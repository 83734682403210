import React from 'react';
import PropTypes from 'prop-types';
import { Store } from 'q3-admin/src/containers/state';

export const isQuoteStatus = (v) =>
  ['Quote', 'Declined', 'Under Review'].includes(v);

export const useLock = () => {
  const { data } = React.useContext(Store);
  return !isQuoteStatus(data.status);
};

const Lock = ({ children, hide, inverse }) => {
  let isOpen = useLock();

  if (inverse) isOpen = !isOpen;
  if (isOpen) return children;
  if (hide) return null;

  return React.cloneElement(children, {
    collectionName: 'orders',
    disabled: true,
  });
};

Lock.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
  inverse: PropTypes.bool,
};

Lock.defaultProps = {
  hide: false,
  inverse: false,
};

export default Lock;
