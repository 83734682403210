import React from 'react';
import Box from '@material-ui/core/Box';
import SearchBar from 'q3-ui/src/searchBar';
import { makeStyles } from '@material-ui/core/styles';
import { getSafelyForAutoCompleteWithProjection } from 'q3-ui-rest';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 12,
    width: 400,
    transition: 'background-color 250ms',
    [theme.breakpoints.down('lg')]: {
      width: 300,
    },
    [theme.breakpoints.down('md')]: {
      width: 270,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      margin: 0,
      padding: 0,
      width: 'auto',
    },

    '&:focus-within': {
      backgroundColor: theme.palette.background.muted,
    },

    '& input': {
      outline: '0 !important',
    },
  },
}));

function debounce(inner, ms = 0) {
  let timer = null;
  let resolves = [];

  return function (...args) {
    // Run the function after a certain amount of time
    clearTimeout(timer);
    timer = setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      const result = inner(...args);
      resolves.forEach((r) => r(result));
      resolves = [];
    }, ms);

    // eslint-disable-next-line
    return new Promise((r) => resolves.push(r));
  };
}

const HeaderSearchBar = () => {
  const { root } = useStyles();

  const go = debounce(
    (input) =>
      getSafelyForAutoCompleteWithProjection(
        '/products?fields=sku,excerpt&limit=8',
        'products',
        'sku',
      )(input),
    300,
  );

  return (
    <Box className={root}>
      <SearchBar
        redirectPath="/search"
        getResults={(e) =>
          go(e).then((r) =>
            r.map((item) => ({
              name: item.sku,
              description: item.excerpt,
              url: `/products/${item.sku.toLowerCase()}`,
              ...item,
            })),
          )
        }
      />
    </Box>
  );
};

export default HeaderSearchBar;
