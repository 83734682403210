import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { navigate, useLocation } from '@reach/router';
import { Context } from 'q3-ui-forms';
import { useTranslation } from 'q3-ui-locale';
import { Next } from 'q3-ui-forms/src/builders';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Pagination, {
  PaginationCard,
  PaginationTypography,
} from 'q3-ui/src/pagination/pagination';

export const SHIPPING = '/checkout';
export const SHIPPING_OPTIONS = '/checkout/delivery';
export const OPTIONS = '/checkout/options';
export const CONFIRM = '/checkout/confirm';

const removeSlash = (pathname) =>
  typeof pathname === 'string'
    ? pathname.replace(/\/$/, '')
    : pathname;

const getPreviousPath = (pathname) => {
  switch (removeSlash(pathname)) {
    case SHIPPING_OPTIONS:
      return SHIPPING;
    case OPTIONS:
      return SHIPPING_OPTIONS;
    case CONFIRM:
      return OPTIONS;
    case SHIPPING:
    default:
      return null;
  }
};

const getNextPath = (pathname) => {
  switch (removeSlash(pathname)) {
    case SHIPPING:
      return SHIPPING_OPTIONS;
    case SHIPPING_OPTIONS:
      return OPTIONS;
    case OPTIONS:
      return CONFIRM;
    case CONFIRM:
    default:
      return null;
  }
};

export const GriddedPaginationCard = ({
  children,
  onClick,
}) => (
  <Next submit>
    {(args) => (
      <Grid item md={6} xs={12}>
        <PaginationCard {...args} onClick={onClick}>
          {children}
        </PaginationCard>
      </Grid>
    )}
  </Next>
);

GriddedPaginationCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};

const CheckoutNavigation = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('descriptions');
  const { onSubmit } = React.useContext(
    Context.BuilderState,
  );

  const prev = getPreviousPath(pathname);
  const next = getNextPath(pathname);

  const submitAndNavigateOnSuccess = (path) => (ev) =>
    onSubmit(ev).then((res) => {
      if (!isNil(res)) navigate(path);
    });

  const getDescription = (path) =>
    typeof path === 'string'
      ? t(path.replace(/\//g, '-').slice(1))
      : null;

  return (
    <Grid item xs={12}>
      <Pagination>
        {prev ? (
          <GriddedPaginationCard
            onClick={submitAndNavigateOnSuccess(prev)}
          >
            <KeyboardArrowLeft />
            <PaginationTypography
              label={t('labels:prev')}
              description={getDescription(prev)}
              align="right"
            />
          </GriddedPaginationCard>
        ) : (
          <Grid item md={6} xs={12} aria-hidden />
        )}
        <GriddedPaginationCard
          onClick={submitAndNavigateOnSuccess(next)}
        >
          <PaginationTypography
            label={t('labels:next')}
            description={getDescription(next)}
          />
          <KeyboardArrowRight />
        </GriddedPaginationCard>
      </Pagination>
    </Grid>
  );
};

CheckoutNavigation.defaultProps = {};
CheckoutNavigation.propTypes = {};

export default CheckoutNavigation;
