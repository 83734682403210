import React from 'react';
import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useResellerLogin from '../useResellerLogin';

const HeaderLogin = () => {
  const { isLoggingIn, isLoggedIn } = useResellerLogin();

  return isLoggingIn || isLoggedIn ? null : (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          size="large"
          component={Link}
          to="/login"
          variant="outlined"
          color="secondary"
          fullWidth
        >
          Login
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="large"
          component={Link}
          to="/signup"
          variant="contained"
          color="secondary"
          fullWidth
        >
          Sign up
        </Button>
      </Grid>
    </Grid>
  );
};

export default HeaderLogin;
