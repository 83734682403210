import React from 'react';
import { LineItem } from 'q3-ui-cart';
import { CartContext } from 'q3-ui-cart/src/context';
import Box from '@material-ui/core/Box';
import { array } from '@lolly-labs/q3-ui-helpers';
import { filter } from 'lodash';
import OrderItemAlerts from './OrderItemAlerts';
import OrderItemCustoms from './OrderItemCustoms';
import OrderItemComments from './OrderItemComments';
import OrderItemCompanion from './OrderItemCompanion';
import OrderItemRebates from './OrderItemRebates';
import OrderItemKit from './OrderItemKit';
import OrderItemProvisioning from './OrderItemProvisioning';
import { AccordionBuilder } from './OrderItemAccordionBuilder';

const hasSpecialProperties = (item) =>
  array.hasLength(item?.bucket?.ZZ_ALERTS) ||
  array.hasLength(item?.bucket?.ZZ_COMMENTS) ||
  array.hasLength(item?.bucket?.ZZ_CUSTOM) ||
  array.hasLength(
    filter(item?.bucket?.ZZ_REBATES, (r) => r?.amount > 0),
  ) ||
  array.hasLength(item?.isCompanionOf) ||
  item?.justificationPublic ||
  item?.bucket?.itemType === 'KIT' ||
  ['ZZ0003', 'ZZ0004'].includes(item?.bucket?.sku);

export default () => (
  <LineItem>
    {(item) =>
      hasSpecialProperties(item) ? (
        <Box mt={1}>
          <AccordionBuilder
            panels={[
              OrderItemAlerts,
              OrderItemCompanion,
              OrderItemCustoms,
              OrderItemKit,
              OrderItemRebates,
              OrderItemComments,
              OrderItemProvisioning,
            ]}
            panelProps={{
              ...React.useContext(CartContext),
              item,
            }}
          />
          {item.disabled && (
            <i>
              This item is currently disabled, which may
              mean that your quote has expired. Please
              contact Gentek to modify this line item.
            </i>
          )}
        </Box>
      ) : null
    }
  </LineItem>
);
