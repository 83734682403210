import React from 'react';
import { get } from 'lodash';
import {
  AuthContext,
  destroySession,
} from 'q3-ui-permissions';
import { isReseller } from 'gatsby-theme-gentek/src/components/helpers';
import { navigate } from '@reach/router';

export default () => {
  const { state } = React.useContext(AuthContext);
  const isLoggedIn = Object.keys(
    get(state, 'profile', {}),
  ).length;

  const redirectToAccountDashboard = React.useCallback(
    () => navigate('/account'),
    [],
  );

  React.useEffect(() => {
    if (
      isLoggedIn &&
      !isReseller(get(state, 'profile.__t'))
    )
      destroySession();
  }, [state]);

  return {
    isLoggedIn,
    ...state.profile,
    redirectToAccountDashboard,
    isLoggingIn: !state.init,
  };
};
