import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const NumberedList = ({ children }) => (
  <ol style={{ marginTop: 0 }}>{children}</ol>
);

NumberedList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NumberedList;

export const NumberedListItem = ({
  text,
  value,
  textArg,
}) => {
  const { t } = useTranslation('labels');
  return (
    <Typography component="li" style={{ margin: 0 }}>
      <strong>{t(text, textArg)}</strong>: {value}
    </Typography>
  );
};

NumberedListItem.defaultProps = {
  textArg: {},
};

NumberedListItem.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  textArg: PropTypes.object,
};
