import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  IconButton,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { map, sortBy } from 'lodash';
// eslint-disable-next-line
import { useToggle } from 'useful-state';
import { isCheckout } from './HideOnCheckout';

const HeaderProductsMenuMobile = () => {
  const { pathname } = useLocation();
  const path = String(pathname);
  const { toggle, state } = useToggle(
    path.includes('product-searches'),
  );

  const data = useStaticQuery(graphql`
    {
      allContentfulProductBucket {
        nodes {
          name
          slug
          type
          sort
        }
      }
    }
  `);

  return isCheckout(pathname) ? null : (
    <>
      <ListItem
        button
        component={Link}
        to="/products"
        selected={
          ['products', 'product-'].some((p) =>
            path.includes(p),
          )
            ? 'secondary'
            : undefined
        }
      >
        <ListItemText primary="Products" />
        <ListItemSecondaryAction>
          <IconButton onClick={toggle}>
            {state ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={state}>
        {map(
          sortBy(
            data.allContentfulProductBucket.nodes,
            'sort',
          ),
          (item, idx) => {
            const to = `/product-searches/${item.slug}`;

            return (
              <li
                key={to}
                style={{
                  marginTop: idx === 0 ? '1rem' : undefined,
                }}
              >
                <ListItem component={Link} dense to={to}>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      style: {
                        paddingLeft: '1rem',
                        color: path.includes(to)
                          ? '#ECAE4B'
                          : 'inherit',
                      },
                    }}
                  />
                </ListItem>
              </li>
            );
          },
        )}
      </Collapse>
    </>
  );
};

export default HeaderProductsMenuMobile;
