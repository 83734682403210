import React from 'react';
import {
  Box,
  Typography,
  Link,
  Grid,
  makeStyles,
} from '@material-ui/core';
import {
  Link as GatsbyLink,
  graphql,
  useStaticQuery,
} from 'gatsby';
import { map, sortBy, get } from 'lodash';

const useStyle = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    margin: `${theme.spacing(0.5)} 0`,
  },
  link: {
    textDecoration: 'none !important',
  },
  title: {
    fontWeight: 'bold',
  },
  column: {
    marginRight: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(2)} 0`,
      width: '100%',
    },
  },
}));

const FooterNavigation = () => {
  const cls = useStyle();
  const { allContentfulService } = useStaticQuery(graphql`
    {
      allContentfulService(
        sort: { order: ASC, fields: title }
      ) {
        nodes {
          title
          to
        }
      }
    }
  `);

  return (
    <Grid container>
      {[
        {
          label: 'Company',
          items: [
            {
              label: 'Products',
              href: '/products',
            },
            {
              label: 'Vendors',
              href: '/vendors',
            },
            {
              label: 'About us',
              href: '/company',
            },
            {
              label: 'Contact',
              href: '/contact',
            },
            {
              label: 'Privacy Policy',
              href: '/privacy-policy',
            },
            {
              label: 'Terms and conditions',
              href: '/terms-and-conditions',
            },
          ],
        },
        {
          label: 'Services',
          items: get(allContentfulService, 'nodes', []).map(
            (item) => ({
              label: item.title,
              href: item.to,
            }),
          ),
        },
      ].map(({ items, label }, i) => (
        <Grid
          item
          key={label}
          className={i === 0 ? cls.column : undefined}
        >
          <Typography
            className={cls.title}
            color="inherit"
            variant="h6"
            component="h5"
          >
            {label}
          </Typography>
          <Box className={cls.list} component="ul">
            {map(sortBy(items, 'label'), (item) => (
              <Box
                className={cls.listItem}
                component="li"
                key={item.label}
              >
                <Link
                  color="inherit"
                  to={item.href}
                  component={GatsbyLink}
                  className={cls.link}
                >
                  {item.label}
                </Link>
              </Box>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default FooterNavigation;
