import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Box,
  Button,
  Grid,
  ListSubheader,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { map, groupBy, orderBy } from 'lodash';
import { isCheckout } from './HideOnCheckout';

const useStyle = makeStyles((theme) => ({
  dropdown: {
    left: '50%',
    opacity: 0,
    position: 'absolute',
    top: '60%',
    transform: 'translateX(-50%)',
    visibility: 'hidden',
    transition: 'all 250ms ease-in',
    width: 550,
    zIndex: 1,
  },
  item: {
    margin: '0 !important',

    '&:hover,&:focus': {
      backgroundColor: theme.palette.background.default,
    },
  },
  hover: {
    '&:focus-within div:last-of-type,&:hover div:last-of-type':
      {
        opacity: '1 !important',
        visibility: 'visible !important',
        top: '110% !important',
      },
  },
}));

const HeaderProductsMenu = () => {
  const cls = useStyle();
  const { pathname } = useLocation();
  const path = String(pathname);

  const data = useStaticQuery(graphql`
    {
      allContentfulProductBucket {
        nodes {
          name
          slug
          type
          sort
        }
      }
    }
  `);

  const blur = (e) => {
    try {
      e.currentTarget.blur();
    } catch (er) {
      // noop
    }
  };

  return isCheckout(pathname) ? null : (
    <Box
      className={cls.hover}
      position="relative"
      display="inline-block"
    >
      <Button
        component={Link}
        data-is-partial={['products', 'product-'].some(
          (p) => path.includes(p),
        )}
        endIcon={<KeyboardArrowDownIcon />}
        to="/products"
      >
        Products
      </Button>
      <Box className={cls.dropdown}>
        <Paper>
          <Box minWidth={550} p={2}>
            <Grid container wrap="nowrap" spacing={2}>
              {Object.entries(
                groupBy(
                  orderBy(
                    data?.allContentfulProductBucket?.nodes,
                    ['type', 'sort'],
                    ['desc', 'asc'],
                  ),
                  'type',
                ),
              ).map(([entry, items]) => (
                <Grid key={entry} item xs={6}>
                  <List
                    subheader={
                      <ListSubheader
                        disableSticky
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {/* Attribute is not user friendly */}
                        {entry === 'Attribute'
                          ? 'Popular choices'
                          : 'Browse by category'}
                      </ListSubheader>
                    }
                  >
                    {map(items, (item) => {
                      const to = `/product-searches/${item.slug}`;

                      return (
                        <li
                          key={to}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <ListItem
                            className={cls.item}
                            component={Link}
                            dense
                            onClick={blur}
                            selected={path.includes(to)}
                            to={to}
                          >
                            <ListItemText
                              primary={item.name}
                            />
                          </ListItem>
                        </li>
                      );
                    })}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default HeaderProductsMenu;
