// eslint-disable-next-line
import React from 'react';
import axios from 'axios';
import { browser } from '@lolly-labs/q3-ui-helpers';
import {
  getCurrency,
  getPaymentOption,
} from '../containers/utils';

const { isDefined } = browser;

const Currency = ({ children }) => {
  const c = getCurrency();
  const p = getPaymentOption();

  if (isDefined(c))
    axios.defaults.headers.common['x-display-currency'] = c;

  if (isDefined(p))
    axios.defaults.headers.common['x-display-gateway'] = p;

  return children;
};

export default Currency;
