import React from 'react';
import Typography from '@material-ui/core/Typography';

const HeaderLogoDesktop = () => (
  <Typography
    component="span"
    variant="h1"
    color="secondary"
    style={{
      fontWeight: 'bold',
      textDecoration: 'none',
      marginRight: '2rem',
    }}
  >
    <img
      src="/logo.png"
      alt="TDL Gentek"
      style={{
        width: 170,
        height: 34,
      }}
    />
  </Typography>
);

export default HeaderLogoDesktop;
