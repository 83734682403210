import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import get from 'lodash.get';

export default (props) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1124 776"
      {...props}
    >
      <ellipse
        cx="562"
        cy="739"
        fill="#3f3d56"
        rx="562"
        ry="37"
      />
      <ellipse
        cx="534"
        cy="216"
        fill={get(theme, 'palette.primary.light')}
        rx="58"
        ry="53"
      />
      <path
        fill="#3f3d56"
        d="M674.5 763.5s-123.5 49.5-211 1l.81-14 7.54-130 7.13-123 8.52-147h164l7.88 147 6.6 123 6.97 130z"
        transform="translate(-38 -62)"
      />
      <path
        fill={get(theme, 'palette.primary.light')}
        d="M674.5 763.5s-113.5 50.5-211 1l.81-14h208.64z"
        transform="translate(-38 -62)"
      />
      <path
        fill={get(theme, 'palette.primary.light')}
        d="M627.98 558.5L433.85 558.5 440.98 435.5 621.38 435.5 627.98 558.5z"
      />
      <path
        fill="#3f3d56"
        d="M439.5 272.5H623.5V292.5H439.5z"
      />
      <path
        fill="#3f3d56"
        d="M439.5 205.5H623.5V225.5H439.5z"
      />
      <path
        fill="#3f3d56"
        d="M455.5 225.5H466.5V277.5H455.5z"
      />
      <path
        fill="#3f3d56"
        d="M484.5 225.5H495.5V277.5H484.5z"
      />
      <path
        fill="#3f3d56"
        d="M513.5 225.5H524.5V277.5H513.5z"
      />
      <path
        fill="#3f3d56"
        d="M542.5 225.5H553.5V277.5H542.5z"
      />
      <path
        fill="#3f3d56"
        d="M571.5 225.5H582.5V277.5H571.5z"
      />
      <path
        fill="#3f3d56"
        d="M484.5 146H495.5V214.5H484.5z"
      />
      <path
        fill="#3f3d56"
        d="M513.5 146H524.5V214.5H513.5z"
      />
      <path
        fill="#3f3d56"
        d="M542.5 146H553.5V214.5H542.5z"
      />
      <path
        fill="#3f3d56"
        d="M571.5 146H582.5V214.5H571.5z"
      />
      <path
        fill="#3f3d56"
        d="M600.5 225.5H611.5V277.5H600.5z"
      />
      <path fill="#3f3d56" d="M456 154H467V215H456z" />
      <path fill="#3f3d56" d="M601 154H612V215H601z" />
      <path
        fill="#3f3d56"
        d="M624 165.5L440 165.5 440 105.5 532 31 624 105.5 624 165.5z"
      />
      <circle
        cx="534"
        cy="215"
        r="154.695"
        fill={get(theme, 'palette.primary.light')}
        opacity="0.3"
      />
      <circle
        cx="534"
        cy="215"
        r="215"
        fill={get(theme, 'palette.primary.light')}
        opacity="0.3"
      />
      <path
        fill="#8985a8"
        d="M89.409 754.734c6.073 22.45 26.875 36.354 26.875 36.354s10.955-22.494 4.882-44.945-26.875-36.353-26.875-36.353-10.956 22.494-4.882 44.944z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#3f3d56"
        d="M98.333 749.91c16.665 16.222 18.7 41.16 18.7 41.16s-24.984-1.364-41.648-17.587-18.7-41.16-18.7-41.16 24.983 1.364 41.648 17.587z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#8985a8"
        d="M354.757 722.408c-8.953 33.096-39.62 53.594-39.62 53.594s-16.15-33.163-7.197-66.259 39.62-53.593 39.62-53.593 16.15 33.162 7.197 66.258z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#8985a8"
        d="M273.309 722.408c8.953 33.096 39.62 53.594 39.62 53.594s16.15-33.163 7.197-66.259-39.62-53.593-39.62-53.593-16.15 33.162-7.197 66.258z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#3f3d56"
        d="M286.465 715.295c24.567 23.916 27.568 60.68 27.568 60.68s-36.831-2.011-61.399-25.927-27.568-60.679-27.568-60.679 36.831 2.011 61.399 25.926zM341.601 715.295c-24.567 23.916-27.568 60.68-27.568 60.68s36.831-2.011 61.399-25.927S403 689.37 403 689.37s-36.831 2.011-61.399 25.926z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#2f2e41"
        d="M946.958 427.973c-7.884-13.322-23.483-13.943-23.483-13.943s-15.2-1.944-24.95 18.346c-9.089 18.912-21.632 37.171-2.02 41.598l3.543-11.026 2.194 11.847a76.737 76.737 0 008.39.144c21.004-.678 41.006.198 40.362-7.339-.856-10.019 3.55-26.809-4.036-39.627z"
        transform="translate(-38 -62)"
      />
      <circle
        cx="887.046"
        cy="383.501"
        r="20.358"
        fill="#a0616a"
      />
      <path
        fill="#a0616a"
        d="M916.718 458.765s9.253 21.591-3.085 25.91l12.338 40.715 20.975-1.234 5.552-13.572-1.234-20.358-1.85-13.571s-12.955 3.084-11.722-19.741zM911.783 484.057s-20.358-1.85-18.507 12.955 3.701 17.89 3.701 17.89l1.85 22.209-11.103 6.786h-10.488s-32.078 7.402-16.04 15.422 21.592-6.169 21.592-6.169 29.612 0 31.462-4.935-2.467-64.158-2.467-64.158zM951.264 477.888s19.124-6.785 20.358 8.02 1.234 16.04 1.234 16.04l3.701 20.974s7.403 18.507 7.403 24.06 5.552 22.824 5.552 22.824 7.403 24.06-.617 23.443-9.253-25.91-9.253-25.91l-16.657-33.313-4.935-20.357z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#d0cde1"
        d="M914.867 481.59h-4.935l-.617 25.91s-8.02 11.104 1.85 20.974l2.468 16.04 45.034-1.851 3.084-16.656s6.17-13.572-2.467-25.91l-8.02-25.293h-4.318s10.001 36.877-11.104 37.63c-17.273.618-20.975-30.844-20.975-30.844z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#2f2e41"
        d="M916.1 541.43l-3.7 12.337s-77.113 217.148-53.67 222.7 56.137 12.955 59.222 7.403 19.123-167.796 19.123-167.796-2.467 169.647 5.553 169.647 61.073 1.234 64.157-6.786-27.76-222.7-45.65-227.636l-4.936-11.104z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#3f3d56"
        d="M960.518 535.26l-48.735 1.85s-2.225 16.657 0 16.657c.617 0 51.202 4.318 50.585-3.084s-1.85-15.423-1.85-15.423z"
        transform="translate(-38 -62)"
      />
      <circle
        cx="887.384"
        cy="352.097"
        r="11.279"
        fill="#2f2e41"
      />
      <path
        fill="#2f2e41"
        d="M912.918 411.13a11.28 11.28 0 0110.092-11.216 11.279 11.279 0 100 22.43 11.279 11.279 0 01-10.092-11.215z"
        transform="translate(-38 -62)"
      />
      <path
        fill="#2f2e41"
        d="M905.472 364.689L890.062 356.616 868.78 359.919 864.377 379.366 875.338 378.944 878.4 371.799 878.4 378.826 883.457 378.632 886.392 367.257 888.227 379.366 906.206 378.999 905.472 364.689z"
      />
      <path
        fill="#3f3d56"
        d="M863.757 494.973H869.626V583.011H863.757z"
        transform="rotate(-16.836 638.213 636.382)"
      />
      <path
        fill={get(theme, 'palette.primary.light')}
        d="M873.438 477.548a24.01 24.01 0 01-45.963 13.908c-3.84-12.692 8.697-54.159 8.697-54.159s33.425 27.559 37.266 40.25z"
        transform="translate(-38 -62)"
      />
      <path
        d="M866.435 484.754a16.346 16.346 0 11-31.29 9.468c-2.615-8.64 5.92-36.87 5.92-36.87s22.756 18.761 25.37 27.402z"
        opacity="0.1"
        transform="translate(-38 -62)"
      />
    </svg>
  );
};
