import React from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import HeaderAccountMenu from './HeaderAccountMenu';
import HeaderNotice from './HeaderNotice';
import HeaderLogin from './HeaderLogin';
import HeaderProgressBar from './HeaderProgressBar';
import HeaderMenuItems from './HeaderMenuItems';
import HeaderSearchBar from './HeaderSearchBar';
import HeaderLogo from './HeaderLogo';
import HeaderLogoDesktop from './HeaderLogoDesktop';
import HeaderMobileMenu from './HeaderMobileMenu';
import HeaderProductsMenu from './HeaderProductsMenu';

const useStyle = makeStyles(() => ({
  list: {
    '& a': {
      margin: '0 .25vw',
      textTransform: 'none',

      '&[data-is-partial=true]': {
        color: '#ECAE4B',
      },
    },
  },
}));

export default () => {
  const cls = useStyle();

  return (
    <Box
      component="header"
      position="relative"
      style={{ zIndex: 10 }}
    >
      <HeaderMenuItems>
        {(menuItems) => (
          <Paper elevation={0}>
            <HeaderNotice />
            <HeaderAccountMenu />
            <AppBar
              color="inherit"
              position="relative"
              component="nav"
              id="app-header"
              style={{
                zIndex: 1,
                borderBottom: '1px solid whitesmoke',
              }}
              elevation={0}
            >
              <Box py={1.5}>
                <Container maxWidth="xl">
                  <Grid
                    alignItems="center"
                    container
                    justify="space-between"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Grid container alignItems="center">
                        <Hidden lgUp>
                          <Grid item>
                            <HeaderMobileMenu
                              menuItems={menuItems}
                            />
                          </Grid>
                        </Hidden>
                        <Grid item>
                          <HeaderLogo>
                            <HeaderLogoDesktop />
                          </HeaderLogo>
                        </Grid>
                        <Hidden mdDown>
                          <Grid item>
                            <HeaderSearchBar />
                          </Grid>
                        </Hidden>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Hidden mdDown>
                        <HeaderProgressBar />
                      </Hidden>
                    </Grid>
                    <Grid item className={cls.list}>
                      <Hidden lgUp>
                        <HeaderSearchBar />
                      </Hidden>
                      <Hidden mdDown>
                        <HeaderProductsMenu />
                        {menuItems.map((item) => (
                          <Button
                            component={Link}
                            getProps={({
                              isPartiallyCurrent,
                            }) => ({
                              'data-is-partial':
                                !!isPartiallyCurrent,
                            })}
                            key={item.label}
                            to={item.to}
                          >
                            {item.label}
                          </Button>
                        ))}
                      </Hidden>

                      <Hidden mdDown>
                        <Box ml={1} display="inline-block">
                          <HeaderLogin />
                        </Box>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </AppBar>
            <Hidden lgUp>
              <HeaderProgressBar />
            </Hidden>
          </Paper>
        )}
      </HeaderMenuItems>
    </Box>
  );
};
