import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Offcanvas from 'accessa/lib/Navigation/Offcanvas';
import IconButton from 'q3-ui/src/iconButton';
import Menu from '@material-ui/icons/Menu';
import HeaderLogin from './HeaderLogin';
import HeaderProductsMenuMobile from './HeaderProductsMenuMobile';

const HeaderMobileMenu = ({ menuItems }) => (
  <Hidden lgUp>
    <Offcanvas
      // eslint-disable-next-line
      menuRenderer={(onClick) => (
        <Box mr={1}>
          <IconButton
            label="menu"
            icon={Menu}
            buttonProps={{
              onClick,
            }}
          />
        </Box>
      )}
    >
      <List>
        <HeaderProductsMenuMobile />
        {menuItems.map((item) => (
          <ListItem
            key={item.to}
            component={Link}
            to={item.to}
            getProps={({ isPartiallyCurrent }) => ({
              selected: isPartiallyCurrent,
            })}
          >
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
      <Box p={1} mt={1}>
        <HeaderLogin />
      </Box>
    </Offcanvas>
  </Hidden>
);

HeaderMobileMenu.defaultProps = {
  menuItems: [],
};

HeaderMobileMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
    }),
  ),
};

export default HeaderMobileMenu;
