import React from 'react';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import { AuthContext } from 'q3-ui-permissions';
import { CartContext } from 'q3-ui-cart/src/context';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { browser } from '@lolly-labs/q3-ui-helpers';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {
  getCurrency,
  setCurrency,
  getPaymentOption,
  setPaymentOption,
  getOrderCurrency,
  removeCurrency,
  removeOrderId,
} from '../containers/utils';

const { isDefined, isBrowserReady } = browser;

export default function CurrencySelect() {
  React.useContext(CartContext);

  const [anchorEl, setAnchorEl] = React.useState();
  const [disableCurrency, setDisableCurrency] =
    React.useState();
  const { state } = React.useContext(AuthContext);
  const [currency, hasCurrency] = React.useState();
  const sessionCurrencyList = get(
    state,
    'profile.currency',
    [],
  ).filter(
    (item) =>
      (item === 'CAD' &&
        get(state, 'profile.session.canadianCode')) ||
      (item === 'USD' &&
        get(state, 'profile.session.americanCode')),
  );

  React.useLayoutEffect(() => {
    const storedPayment = getPaymentOption();
    const storedCurrency = getCurrency();
    const orderCurrency = getOrderCurrency();

    let activeCurrency;
    let disabled = false;

    if (sessionCurrencyList.length === 1) {
      [activeCurrency] = sessionCurrencyList;
      disabled = true;
    } else if (isDefined(orderCurrency)) {
      disabled = true;
      activeCurrency = orderCurrency;
    } else if (isDefined(storedCurrency)) {
      activeCurrency = storedCurrency;
    } else {
      [activeCurrency] = sessionCurrencyList;
    }

    if (
      activeCurrency &&
      sessionCurrencyList.includes(activeCurrency)
    ) {
      setCurrency(activeCurrency);
      hasCurrency(activeCurrency);
      setDisableCurrency(disabled);
    } else {
      removeCurrency();
      removeOrderId();
      hasCurrency(false);
    }

    const activePayment = get(
      state,
      'profile.session.preferredPaymentOption',
      'Banking',
    );

    setPaymentOption(activePayment);

    if (
      activeCurrency !== storedCurrency ||
      activePayment !== storedPayment
    ) {
      try {
        window.location.reload();
      } catch (e) {
        // noop
      }
    }
  }, [sessionCurrencyList]);

  // public cannot
  if (state.init && !state.profile) return null;

  return currency ? (
    <>
      <Button
        id="currency-selector"
        aria-haspopup="true"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={
          disableCurrency || sessionCurrencyList.length < 2
        }
        startIcon={<MonetizationOnIcon />}
      >
        {currency}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {sessionCurrencyList.map((exch) => (
          <MenuItem
            dense
            key={exch}
            onClick={() => {
              setCurrency(exch);
              hasCurrency(exch);

              if (isBrowserReady())
                window.location.reload();
            }}
            style={{
              margin: 0,
              fontSize: '1rem',
            }}
          >
            {exch}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
}
