import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'block',
    padding: `${theme.spacing(1)} 0`,
    textAlign: 'left',

    '& a': {
      color: 'inherit',
      fontSize: 'inherit',

      '&::before, &::after': {
        content: '" "',
      },
    },
  },
}));

export default () => (
  <Typography
    className={useStyle().root}
    color="inherit"
    component="caption"
    variant="caption"
  >
    This site is protected by reCAPTCHA and the Google
    <a
      href="https://policies.google.com/privacy"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
    and
    <a
      href="https://policies.google.com/terms"
      target="_blank"
      rel="noreferrer"
    >
      Terms of Service
    </a>
    apply. <br />© {new Date().getFullYear()}. All rights
    reserved
  </Typography>
);
