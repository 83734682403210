import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'q3-ui-locale';
import Typography from '@material-ui/core/Typography';
import { useAuth } from 'q3-ui-permissions';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import NumberedList from './NumberedList';
import { COLLECTIONS } from './constants';
import { ensureArray } from './VendorClaim';

export const getDiscountParts = (item) => ({
  alerts: get(item, 'bucket.ZZ_ALERTS', []),
  deal: get(item, 'bucket.ZZ_DEAL', false),
  discount: get(item, 'bucket.ZZ_DISCOUNTS', []),
  rebates: get(item, 'bucket.ZZ_REBATES', []),
  id: get(item, 'id'),
  justification: get(item, 'justification'),
  justificationPublic: get(item, 'justificationPublic'),
  override: get(item, 'priceOverride', null),
});

const useDiscountText = ({
  formula,
  strategy,
  factor,
  option,
  paymentOptionRate,
  activeFactor,
  isOverride,
  from,
}) => {
  const { t } = useTranslation('descriptions');

  let text = t(`${formula.toLowerCase()}`, {
    strategy: strategy === 'custom' ? 'reseller' : strategy,
    factor: parseFloat(
      Number(activeFactor || factor).toFixed(4),
    ),
    from,
  });

  if (option && isOverride)
    text += ` ${t('basePaymentOption', {
      option,
    })}`;

  if (paymentOptionRate && isOverride)
    text += ` (${paymentOptionRate}%)`;

  return text;
};

export const DiscountDescription = ({
  item,
  override,
  inherited,
  excludeFromRebating,
  children,
  base,
  isOverride,
  ...rest
}) => {
  const text = useDiscountText({
    isOverride,
    option: get(item, 'priceOverride.paymentOption'),
    paymentOptionRate: get(
      item,
      'priceOverride.paymentOptionRate',
    ),
    from:
      rest.from ||
      get(item, 'bucket.currencyDefault', 'CAD'),
    ...rest,
  });

  const { t } = useTranslation('descriptions');
  const hasBase = (v) =>
    ['Incremental', 'Compound'].includes(v);

  const getText = React.useCallback(() => {
    return inherited ? (
      <span>
        {text} (
        <em>{`Inherited from ${inherited} Association`}</em>
        )
      </span>
    ) : (
      text
    );
  }, [text, inherited]);

  return (
    <Typography component="li">
      <Typography
        component={
          Boolean(override) && !hasBase(override.formula)
            ? 'strike'
            : 'span'
        }
      >
        <ul>
          <li>{getText()}</li>
          {isOverride ? (
            <li>{t('discountFromOverride')}</li>
          ) : (
            <>
              <li>
                {t(
                  inherited
                    ? 'discountFromAssociation'
                    : 'discountFromCompany',
                  {
                    inherited,
                  },
                )}
              </li>

              <li>
                {t(
                  excludeFromRebating
                    ? 'hasBeenExcludedFromRebating'
                    : 'hasNotBeenExcludedFromRebating',
                )}
              </li>
              {rest.locale ? (
                <li>
                  {`Converted at $${rest.locale.factor} ${
                    rest.locale.from
                  } when loaded into this ${get(
                    item,
                    'bucket.currencyDefault',
                  )} product`}
                </li>
              ) : (
                <li>
                  Processed in the products's base currency
                </li>
              )}
            </>
          )}
        </ul>
      </Typography>
    </Typography>
  );
};

DiscountDescription.propTypes = {
  children: PropTypes.element,
  override: PropTypes.bool.isRequired,
  strategy: PropTypes.string,
  formula: PropTypes.string,
  factor: PropTypes.number.isRequired,
};

DiscountDescription.defaultProps = {
  children: null,
  formula: '',
  strategy: '',
};

const {
  ORDERS: { COLLECTION_NAME },
} = COLLECTIONS;

const OrderItemDiscounts = ({ item, update, ...rest }) => {
  const { discount, override } = getDiscountParts(item);
  const { HideByField } = useAuth(COLLECTION_NAME);
  const discounts = ensureArray(discount);

  return discounts.length || override ? (
    <HideByField op="Read" path="price" subfield="bucket">
      <CollapsiblePanel title="discounts" {...rest}>
        <NumberedList>
          {discounts.map((dis, idx) => (
            // eslint-disable-next-line
            <React.Fragment key={`${dis.id}${idx}`}>
              <DiscountDescription
                override={override}
                item={item}
                {...rest}
                {...dis}
              />
            </React.Fragment>
          ))}
          {override && (
            <DiscountDescription
              isOverride
              {...rest}
              {...override}
            />
          )}
        </NumberedList>
      </CollapsiblePanel>
    </HideByField>
  ) : null;
};

OrderItemDiscounts.propTypes = {
  update: PropTypes.func.isRequired,
  item: PropTypes.shape({
    bucket: PropTypes.object,
    priceOverride: PropTypes.object,
    justification: PropTypes.string,
    justificationPublic: PropTypes.string,
  }).isRequired,
};

export default OrderItemDiscounts;
