import 'q3-ui-permissions';
import React from 'react';
import { get, size, compact } from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';
import CartProvider from 'q3-ui-cart';
import { getTitle } from 'gatsby-theme-gentek/src/components/OrderItemAccordionBuilder';
import {
  getOrderId,
  setOrderId,
  setOrderCurrency,
  removeOrderId,
} from './utils';

const marshalItemsIntoCart = (order) =>
  Object.assign(order, {
    items: order.items.map((item) => ({
      ...item,
      id: item.id,
      name: getTitle(item),
      img: item?.bucket?.photo,
      description: item?.bucket?.excerpt,
      disableQuantity:
        get(item, 'automated') &&
        !size(compact(get(item, 'isCompanionOf'))),
      disableRemove: !get(item, 'canBeRemoved', true),
    })),
  });

export const deleteOrder = (id) =>
  axios
    .delete(`/orders/${id}/`)
    .then(() => null)
    .catch(() => null)
    .finally(() => {
      removeOrderId();
      window.location.replace('/products');
    });

export const providerServices = {
  clear: () => deleteOrder(getOrderId()),

  addItemToOrder: (args) => {
    const id = getOrderId();

    const postToItems = (v) =>
      axios.post(`/orders/${v}/items`, args);

    return id && id !== 'undefined'
      ? postToItems(id)
      : axios
          .post('/orders')
          .then(({ data: { order } }) => {
            setOrderId(order.id);
            return postToItems(order.id);
          });
  },

  updateItemInOrder: (args) =>
    axios.patch(
      `/orders/${getOrderId()}/items/${args.id}`,
      args,
    ),

  removeItemInOrder: (id) =>
    axios.delete(`/orders/${getOrderId()}/items/${id}`),

  pollOrder: () => {
    const id = getOrderId();

    if (!id) return Promise.resolve();

    return axios
      .get(`/orders/${getOrderId()}`)
      .then(({ data: { order } }) => {
        if (order.status !== 'Quote')
          throw new Error(
            'This order is no longer editable',
          );

        setOrderCurrency(order.currency);
        return marshalItemsIntoCart(order);
      });
  },
};

const Cart = ({ children }) => (
  <CartProvider {...providerServices}>
    {children}
  </CartProvider>
);

Cart.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Cart;
