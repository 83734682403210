import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import { string } from '@lolly-labs/q3-ui-helpers';

export const getCustoms = (item) =>
  get(item, 'bucket.ZZ_CUSTOM', []);

const removeSpecialChar = (v) =>
  typeof v === 'string' ? v.replace(':', '') : '';

const OrderItemCustoms = ({ item, ...rest }) =>
  getCustoms(item).map((c) => (
    <CollapsiblePanel
      key={c.comment}
      title="ZZCustom"
      description={`${removeSpecialChar(
        c.comment,
      )} (${string.toPrice(c.price)})`}
      success
      {...rest}
    />
  ));

OrderItemCustoms.defaultProps = {
  item: {},
};

OrderItemCustoms.propTypes = {
  item: PropTypes.shape({
    bucket: PropTypes.shape({
      ZZ_CUSTOMS: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default OrderItemCustoms;
