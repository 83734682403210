import React from 'react';
import { Form, Field } from 'q3-ui-forms/src/builders';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import { COLLECTIONS } from './constants';

const ItemProvisioning = ({ update, item, ...rest }) =>
  ['ZZ0003', 'ZZ0004'].includes(item?.bucket?.sku) && (
    <CollapsiblePanel
      title="provisioning"
      warning={!item.provisioning}
      description={
        !item.provisioning
          ? 'informationMayBeExpected'
          : null
      }
      {...rest}
    >
      <Form
        onSubmit={update}
        initialValues={item}
        collectionName={COLLECTIONS.ORDERS.COLLECTION_NAME}
      >
        <Field
          name="provisioning"
          under="items"
          type="text"
          multiline
          rows={5}
          required
        />
      </Form>
    </CollapsiblePanel>
  );

export default ItemProvisioning;
