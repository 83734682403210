import React from 'react';
import { compact, get, map, upperCase } from 'lodash';
import { useTranslation } from 'q3-ui-locale';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import { string } from '@lolly-labs/q3-ui-helpers';
import NumberedList, {
  NumberedListItem,
} from './NumberedList';
import { ensureArray } from './VendorClaim';

export const getRebates = (item) =>
  ensureArray(get(item, 'bucket.ZZ_REBATES', [])).filter(
    (xs) => get(xs, 'amount', 0) > 0,
  );

export const RebateConditionalSku = ({
  conditionalSkus,
  conditionalSkuThreshold,
}) => {
  const { t } = useTranslation('descriptions');
  const skus = Array.isArray(conditionalSkus)
    ? compact(conditionalSkus.map(upperCase)).join(', ')
    : String(conditionalSkus).toUpperCase();

  return skus ? (
    <NumberedListItem
      text="conditions"
      value={t('conditionalSkusInstruction', {
        quantity: conditionalSkuThreshold,
        skus,
      })}
    />
  ) : null;
};

RebateConditionalSku.defaultProps = {
  conditionalSkus: [],
  conditionalSkuThreshold: 0,
};

RebateConditionalSku.propTypes = {
  conditionalSkus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  conditionalSkuThreshold: PropTypes.number,
};

export const RebateTiersList = ({ sku, tiers }) => {
  const { t } = useTranslation('descriptions');

  return map(tiers, ({ quantity, value: tierValue }) => (
    <NumberedListItem
      text="tierValue"
      textArg={{
        value: tierValue,
      }}
      value={t('tierQuantity', {
        quantity,
        sku,
      })}
    />
  ));
};

RebateTiersList.propTypes = {
  sku: PropTypes.string.isRequired,
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number,
      value: PropTypes.number,
    }),
  ),
};

export const RebateExpires = ({ expiresOn }) =>
  expiresOn &&
  moment(expiresOn, moment.ISO_8601).isValid() ? (
    <NumberedListItem
      text="expiresOn"
      value={moment(expiresOn).format('YYYY-MM-DD')}
    />
  ) : null;

RebateExpires.defaultProps = {
  expiresOn: null,
};

RebateExpires.propTypes = {
  expiresOn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export const RebateList = ({ rebates, sku }) => (
  <NumberedList>
    {rebates.map(
      ({
        name,
        value,
        description,
        conditionalSkus,
        conditionalSkuThreshold = 1,
        url,
        expiresOn,
        tiers,
      }) => (
        <Typography component="li" key={name}>
          <ul>
            <NumberedListItem
              text="rebate"
              value={`${name} (${string.toPrice(value)})`}
            />
            {description && (
              <NumberedListItem
                text="description"
                value={description}
              />
            )}
            <RebateConditionalSku
              conditionalSkus={conditionalSkus}
              conditionalSkuThreshold={
                conditionalSkuThreshold
              }
            />
            {url && (
              <NumberedListItem
                text="Link"
                value={
                  <Link
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See all promotion details
                  </Link>
                }
              />
            )}
            <RebateExpires expiresOn={expiresOn} />
            <RebateTiersList sku={sku} tiers={tiers} />
          </ul>
        </Typography>
      ),
    )}
  </NumberedList>
);

RebateList.propTypes = {
  rebates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      amount: PropTypes.number,
    }),
  ).isRequired,
  sku: PropTypes.string.isRequired,
};

const OrderItemRebates = ({ item, ...rest }) => {
  const rebates = getRebates(item);

  return rebates.length ? (
    <CollapsiblePanel title="rebates" {...rest}>
      <RebateList rebates={rebates} />
    </CollapsiblePanel>
  ) : null;
};

OrderItemRebates.defaultProps = {
  item: {},
};

OrderItemRebates.propTypes = {
  item: PropTypes.shape({
    bucket: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      ZZ_REBATES: PropTypes.array,
    }),
  }),
};
export default OrderItemRebates;
