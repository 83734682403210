import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';

const getDescription = (item) =>
  [
    get(item, 'bucket.manufacturerSku'),
    get(item, 'bucket.excerpt'),
  ].join('—');

const OrderItemDescription = (props) => {
  const { t } = useTranslation('labels');
  const datasheet = get(props, 'bucket.datasheet', false);

  return (
    <Grid container component="span">
      <Grid item component="span">
        {getDescription(props)}
      </Grid>
      {datasheet && (
        <Grid
          item
          xs={12}
          component="a"
          href={datasheet}
          target="_blank"
          rel="noreferrer"
        >
          {t('viewDatasheet')}
        </Grid>
      )}
    </Grid>
  );
};

export default OrderItemDescription;
