import React from 'react';
import moment from 'moment';
import { compact, join, get } from 'lodash';
import Assistant from '@material-ui/icons/Assistant';
import Contacts from '@material-ui/icons/Contacts';
import { purple, blueGrey } from '@material-ui/core/colors';
import { RESELLER } from './constants';

export const isReady = () => typeof window !== 'undefined';

export const hexToRgb = (hex) => {
  if (!hex) return {};
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  // eslint-disable-next-line
  hex = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b,
  );

  const result =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const castArrayToUpperCase = (a = []) =>
  Array.isArray(a)
    ? a.map((s) => s.toUpperCase()).join(', ')
    : '!*';

export const convertToNullish = (v) => {
  if (typeof v === 'string' && !v.length) return null;

  return v;
};

/** THIS NEEDS TO BE INVESTIGATED... */
export const castToBoolean = (v) => `${Boolean(v)}`;

export const toString = (v) => (v ? String(v) : '0');

export const toDate = (d) =>
  moment(d).format('MMMM Do, YYYY, h:mm:ss a');

export const asMarshals = (arr = []) =>
  arr.reduce(
    (acc, next) =>
      Object.assign(acc, {
        [next]: next,
      }),
    {},
  );

export const printMadeFor = (madeFor, belongsTo) => {
  if (!madeFor && !belongsTo) return '--';
  return (
    <>
      {get(madeFor, 'name')}
      <br />
      {join(
        compact([
          get(belongsTo, 'firstName'),
          get(belongsTo, 'lastName'),
        ]),
        ' ',
      )}
    </>
  );
};

export const ellipsis = (value, chars = 160) => {
  if (!value || typeof value !== 'string') return '';

  return value.length > chars
    ? `${String(value).substring(0, chars)}...`
    : value;
};

//= ===============================================================================
// Contacts & Companies
//= ===============================================================================

export const isReseller = (v) => v === RESELLER;

//= ===============================================================================
// Orders
//= ===============================================================================

export const isReadyForCreditCardProcessing = (order) =>
  ['Open', 'Declined'].includes(order.status) &&
  order.isPayingWithCreditCard;

export const filterSkuless = (a) =>
  a.filter(({ sku }) => sku && sku !== 0 && sku !== '0');

export const getContactName = (order) =>
  order
    ? [
        get(order, 'belongsTo.firstName'),
        get(order, 'belongsTo.lastName'),
      ].join(' ')
    : '';
export const getEmail = (order) => {
  const email = get(order, 'belongsTo.email', null);
  return email ? ` <${email}>` : '';
};

export const getCustomerName = (order) =>
  get(order, 'for.name', '');

export const getOrderCustomerId = (order) =>
  get(order, 'for.ref');

export const getVendorId = (order) =>
  get(order, 'vendor.ref', null);

export const getContactId = (order) =>
  order ? get(order, 'belongsTo.ref') : '';

export const getAlerts = (item) =>
  get(item, 'bucket.ZZ_ALERTS', []);

export const getCustoms = (item) =>
  get(item, 'bucket.ZZ_CUSTOM', []);

export const getRebates = (item) =>
  get(item, 'bucket.ZZ_REBATES', []);

export const getTitle = (item) => `${item.bucket.sku}`;

export const getDescription = (item) =>
  `${item.bucket.manufacturerSku} -- ${item.bucket.excerpt}`;

export const getItemAttributes = (item) => ({
  alerts: getAlerts(item),
  components: filterSkuless(
    get(item, 'bucket.components', []),
  ),
  comments: get(item, 'bucket.ZZ_COMMENTS', []),
  currency: get(item, 'bucket.currencyDefault'),
  customs: getCustoms(item),
  deal: get(item, 'bucket.ZZ_DEAL', false),
  discount: get(item, 'bucket.ZZ_DISCOUNTS', null),
  ellaboration: get(item, 'ellaboration'),
  id: get(item, 'id'),
  justification: get(item, 'justification'),
  justificationPublic: get(item, 'justificationPublic'),
  message: get(item, 'bucket.restricted.comment'),
  ...get(item, 'bucket.price', {
    reseller: 0,
    volume: 0,
    msrp: 0,
  }),
  override: get(item, 'priceOverride', null),
  rate: get(item, 'bucket.price.trace.exchangeRate'),
  rebates: getRebates(item),
  sku: get(item, 'bucket.sku'),
  stamp: get(item, 'bucket.ZZ_VALID'),
  tracking: get(item, 'tracking', []),
});

export const isChangeable = (status) =>
  ['Quote', 'Declined'].includes(status);

export const isOpen = (status) =>
  isChangeable(status) || ['Under Review'].includes(status);

//= ===============================================================================
// Returns
//= ===============================================================================

export const getUserId = (val, state) =>
  get(state, 'user.id');

export const getCustomerId = (val, state) =>
  get(state, 'user.customer.ref');

//= ===============================================================================
// Vendors
//= ===============================================================================

export const genCompanyAndContacts = (t, props) => {
  const out = [];
  const contact = getContactId(props);
  const customer = getOrderCustomerId(props);

  if (customer)
    out.push({
      icon: Assistant,
      color: blueGrey[500],
      title: t('labels:customer'),
      description: `${getCustomerName(props)}`,
      href: customer
        ? `/app/companies/${customer}`
        : undefined,
    });

  if (contact)
    out.push({
      icon: Contacts,
      color: purple[500],
      title: t('labels:contact'),
      description: `${getContactName(props)} ${getEmail(
        props,
      )}`,
      href: `/app/users/${contact}`,
    });

  return out;
};
