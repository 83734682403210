import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { get, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import { useAuth } from 'q3-ui-permissions';
import { string } from '@lolly-labs/q3-ui-helpers';
import { Store } from 'q3-admin/src/containers/state';
import NumberedList, {
  NumberedListItem,
} from './NumberedList';
import { COLLECTIONS } from './constants';
import OrderItemInventoryInformation from './ProductInventory';

const toFixed = (v, suffix = '') =>
  isNumber(v) ? `${Number(v).toFixed(2)}${suffix}` : '--';

export const OrderItemInformation = ({ item, ...rest }) => {
  const { data } = React.useContext(Store);
  const { t } = useTranslation();
  const { canSeeSub, HideByField } = useAuth(
    COLLECTIONS.ORDERS.COLLECTION_NAME,
  );

  // eslint-disable-next-line
  const { esp, espf, epm } = item;
  const {
    msrp,
    volume,
    custom,
    mrp,
    reseller,
    landedCost,
  } = get(item, 'bucket.price', {});

  const renewal = get(item, 'bucket.daysUntilRenewal', 0);

  if (!canSeeSub('bucket.price.reseller')) return null;

  return (
    <CollapsiblePanel title="productInformation" {...rest}>
      <Box mb={1}>
        <Alert severity="warning">
          {t('descriptions:productInformationOrder')}
        </Alert>
      </Box>
      <Typography variant="overline">
        {t('titles:priceList')}:
      </Typography>
      <Typography variant="body1">
        {t('descriptions:priceList', {
          currency: data?.currency,
        })}
      </Typography>
      <NumberedList>
        <NumberedListItem
          text="sellingPriceExpanded"
          value={string.toPrice(custom)}
        />
        <HideByField path="items.epm" op="Read">
          <NumberedListItem
            text="effectiveSellingPrice"
            value={string.toPrice(esp)}
          />
          <NumberedListItem
            text="effectiveSellingPriceFactor"
            value={toFixed(espf)}
          />
          <NumberedListItem
            text="estimatedProfitMargin"
            value={toFixed(epm, '%')}
          />
        </HideByField>
        <NumberedListItem
          text="landedCostEstimate"
          value={string.toPrice(landedCost)}
        />
        <NumberedListItem
          text="Regular reseller price"
          value={string.toPrice(reseller)}
        />
        <NumberedListItem
          text="msrp"
          value={string.toPrice(msrp)}
        />
        <NumberedListItem
          text="volumePrice"
          value={string.toPrice(volume)}
        />
        <NumberedListItem
          text="mrp"
          value={string.toPrice(mrp)}
        />
      </NumberedList>
      <Typography variant="overline">
        {t('titles:inventory')}:
      </Typography>
      <br />
      <OrderItemInventoryInformation {...item} />
      <br />
      <Typography variant="overline">
        {t('titles:attributes')}:
      </Typography>

      <NumberedList>
        <NumberedListItem
          text="daysUntilRenewal"
          value={renewal === 0 ? 'N/A' : renewal}
        />
        <NumberedListItem
          text="scanning"
          value={get(item, 'bucket.ZZ_SCANNING', 0)}
        />
      </NumberedList>
    </CollapsiblePanel>
  );
};

OrderItemInformation.propTypes = {
  item: PropTypes.shape({
    price: PropTypes.shape({
      raw: PropTypes.number.isRequired,
      msrp: PropTypes.number.isRequired,
      volume: PropTypes.number.isRequired,
      custom: PropTypes.number.isRequired,
      reseller: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default OrderItemInformation;
