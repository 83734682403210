import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';

function Icon() {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="926.622"
      height="600.085"
      data-name="Layer 1"
      viewBox="0 0 926.622 600.085"
    >
      <circle
        cx="150.534"
        cy="254.872"
        r="24.561"
        fill="#ffb8b8"
      />
      <path
        fill="#ffb8b8"
        d="M67.543 549.197L73.762 559.763 117.474 540.803 108.295 525.21 67.543 549.197z"
      />
      <path
        fill="#2f2e41"
        d="M206.096 694.43l12.247 20.807a15.386 15.386 0 01-5.455 21.065l-.43.254-20.053-34.067z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill="#ffb8b8"
        d="M139.6 587.26L151.86 587.259 157.692 539.971 139.598 539.972 139.6 587.26z"
      />
      <path
        fill="#2f2e41"
        d="M273.162 733.215l24.144-.001h.001a15.386 15.386 0 0115.387 15.386v.5l-39.531.001zM227 708.567a4.493 4.493 0 01-3.623-1.833l-11.824-16.058a4.5 4.5 0 011.124-6.41l52.614-35.142-31.746-83.475 67.889 5.603 2.287 71.733a36.046 36.046 0 01-18.046 32.424l-56.426 32.554a4.486 4.486 0 01-2.248.604z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill="#2f2e41"
        d="M276.66 727.617a4.5 4.5 0 01-4.49-4.34l-2.487-69.882 30.808-1.623-2.453 70.384a4.487 4.487 0 01-4.2 4.333l-16.873 1.118a4.6 4.6 0 01-.306.01z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M309.29 585.129c-7.837 0-35.216-8.817-44.72-11.96-1.422 1.138-9.233 6.98-18.351 5.912-5.626-.66-10.58-3.845-14.721-9.466-9.147-12.414 2.15-65.651 13.239-108.126a34.268 34.268 0 0140.528-24.88 34.571 34.571 0 0126.088 25.378c2.088 8.498.292 18.494-1.02 25.794-.616 3.432-1.103 6.143-.937 7.475.644 5.148 4.22 85.891 1.92 89.34-.245.367-.953.533-2.025.533z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill="#ffb8b8"
        d="M280.616 585.272a10.743 10.743 0 00.942-16.446l.485-97.843-23.045 3.837 6.77 94.97a10.8 10.8 0 0014.848 15.482z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M285.946 533.818h-24.822a4.514 4.514 0 01-4.5-4.459l-.61-67.292a17.603 17.603 0 1135.205.043l-.773 67.26a4.515 4.515 0 01-4.5 4.448z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill="#2f2e41"
        d="M280.97 429.289c-11.246.02-21.855-8.868-23.804-19.944a28.671 28.671 0 01.92-13.013 34.954 34.954 0 0147.935-21.953c3.008 1.361 5.971 3.316 7.349 6.317s.515 7.196-2.498 8.546c-1.97.882-4.44.45-6.187 1.716-2.51 1.82-1.864 5.691-.743 8.58s2.388 6.388.51 8.853c-1.75 2.298-5.25 2.226-8.005 1.362s-5.476-2.313-8.349-2.019c-4.19.43-7.201 4.598-7.759 8.773s.755 8.35 2.05 12.358z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill="#ccc"
        d="M395.69 750.042h-258a1 1 0 110-2h258a1 1 0 010 2z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill="#f2f2f2"
        d="M1046.81 584.505H405.973a16.519 16.519 0 01-16.5-16.5V172.852a12.102 12.102 0 0112.088-12.089h649.454a12.311 12.311 0 0112.297 12.298v394.944a16.519 16.519 0 01-16.5 16.5z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill="#fff"
        d="M1026.091 563.673H428.846c-8.755 0-15.877-6.67-15.877-14.868V204.7c0-6.08 5.278-11.027 11.765-11.027h605.274c6.595 0 11.96 5.028 11.96 11.209v343.923c0 8.198-7.122 14.868-15.877 14.868z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill="#3f3d56"
        d="M1063.081 174.908h-673.84v-8.4a16.574 16.574 0 0116.56-16.55h640.72a16.574 16.574 0 0116.56 16.55z"
        transform="translate(-136.69 -149.958)"
      />
      <circle
        cx="282.647"
        cy="12.5"
        r="4.283"
        fill="#fff"
      />
      <circle
        cx="298.906"
        cy="12.5"
        r="4.283"
        fill="#fff"
      />
      <circle
        cx="315.165"
        cy="12.5"
        r="4.283"
        fill="#fff"
      />
      <path
        fill="#ccc"
        d="M713.3 268.912H596.492a6.566 6.566 0 010-13.132H713.3a6.566 6.566 0 110 13.132zM858.446 268.912H741.638a6.566 6.566 0 010-13.132h116.808a6.566 6.566 0 110 13.132zM858.446 293.794H655.24a6.566 6.566 0 110-13.132h203.205a6.566 6.566 0 110 13.132zM713.3 243.684H596.492a6.566 6.566 0 010-13.132H713.3a6.566 6.566 0 110 13.132zM777.579 244.03h-33.177a6.566 6.566 0 010-13.133h33.177a6.566 6.566 0 110 13.133zM629.668 293.794h-33.176a6.566 6.566 0 010-13.132h33.176a6.566 6.566 0 110 13.132zM713.3 501.912H596.492a6.566 6.566 0 010-13.132H713.3a6.566 6.566 0 110 13.132zM858.446 501.912H741.638a6.566 6.566 0 010-13.132h116.808a6.566 6.566 0 110 13.132zM858.446 526.794H655.24a6.566 6.566 0 110-13.132h203.205a6.566 6.566 0 110 13.132zM713.3 476.684H596.492a6.566 6.566 0 010-13.132H713.3a6.566 6.566 0 110 13.132zM777.579 477.03h-33.177a6.566 6.566 0 010-13.132h33.177a6.566 6.566 0 010 13.132zM629.668 526.794h-33.176a6.566 6.566 0 010-13.132h33.176a6.566 6.566 0 110 13.132z"
        transform="translate(-136.69 -149.958)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M682.793 431.42a9.397 9.397 0 01-6.624-2.71l-43.908-43.36a9.152 9.152 0 010-13.024l43.731-43.184a9.702 9.702 0 0113.644.043 9.689 9.689 0 010 13.687l-32.496 32.496a5.413 5.413 0 000 7.647l32.32 32.32a9.418 9.418 0 01-6.667 16.084zM752.759 431.42a9.418 9.418 0 01-6.667-16.085l32.32-32.32a5.413 5.413 0 000-7.647l-32.496-32.496a9.678 9.678 0 010-13.687 9.702 9.702 0 0113.644-.043l43.73 43.184a9.152 9.152 0 010 13.024l-43.908 43.36a9.399 9.399 0 01-6.623 2.71z"
        transform="translate(-136.69 -149.958)"
      />
    </svg>
  );
}

export default Icon;
