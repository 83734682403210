import React from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { browser } from '@lolly-labs/q3-ui-helpers';
import { isFunction } from 'lodash';
import HydrationFix from './HydrationFix';

const ANALYTICS =
  'function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","UA-43348114-35");';

const GoogleTag = () => {
  const l = useLocation();
  const page = l?.pathname;

  React.useEffect(() => {
    if (
      page &&
      browser.isBrowserReady() &&
      isFunction(window.ga)
    ) {
      try {
        window.ga.getAll()[0].send('pageview', page);
      } catch (e) {
        // noop
      }
    }
  }, [page]);

  return (
    <Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-43348114-35"
      />
      <script>{ANALYTICS}</script>
    </Helmet>
  );
};

export default () => (
  <HydrationFix>
    <GoogleTag />
  </HydrationFix>
);
