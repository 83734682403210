import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import get from 'lodash.get';

export default (props) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="974.967"
      height="773.958"
      viewBox="0 0 974.967 773.958"
      {...props}
    >
      <circle
        cx="520.967"
        cy="99.958"
        r="64"
        fill="#ff6584"
      />
      <path
        fill="#f2f2f2"
        d="M440.78 470.993c0 123.61-73.485 166.77-164.132 166.77s-164.131-43.16-164.131-166.77S276.648 190.13 276.648 190.13 440.78 347.383 440.78 470.993z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill="#3f3d56"
        d="M158.153 555.833L159.833 452.383 229.79 324.4 160.097 436.154 160.852 389.639 209.067 297.044 161.052 377.329 161.052 377.329 162.412 293.669 214.04 219.952 162.625 280.514 163.474 127.11 158.138 330.188 158.576 321.811 106.085 241.464 157.735 337.893 152.844 431.327 152.698 428.847 92.185 344.294 152.515 437.608 151.903 449.293 151.794 449.469 151.844 450.428 139.435 687.478 156.014 687.478 158.003 565.037 218.185 471.952 158.153 555.833z"
      />
      <path
        fill="#f2f2f2"
        d="M927.164 406.143c0 151.011-89.774 203.739-200.516 203.739s-200.516-52.728-200.516-203.74S726.648 63.022 726.648 63.022s200.516 192.11 200.516 343.122z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill="#3f3d56"
        d="M606.827 523.761L608.88 397.378 694.345 241.024 609.202 377.552 610.126 320.725 669.028 207.605 610.37 305.687 610.37 305.687 612.03 203.481 675.104 113.423 612.291 187.409 613.329 0 606.809 248.096 607.345 237.861 543.218 139.704 606.317 257.508 600.341 371.654 600.164 368.625 526.236 265.329 599.94 379.328 599.192 393.603 599.059 393.818 599.12 394.99 583.96 684.589 604.214 684.589 606.645 535.005 680.167 421.285 606.827 523.761z"
      />
      <ellipse
        cx="399.967"
        cy="684.958"
        fill="#3f3d56"
        rx="308"
        ry="11"
      />
      <ellipse
        cx="832.967"
        cy="760.458"
        fill="#3f3d56"
        rx="142"
        ry="13.5"
      />
      <path
        fill="#2f2e41"
        d="M381.967 346.958H417.967V684.9580000000001H381.967z"
      />
      <path
        fill="#3f3d56"
        d="M564.967 423.958L343.967 423.958 343.967 361.958 564.967 361.958 628.967 392.958 564.967 423.958z"
      />
      <path
        d="M564.967 418.958L343.967 418.958 343.967 423.958 564.967 423.958 628.967 392.958 623.806 390.458 564.967 418.958z"
        opacity="0.1"
      />
      <path
        fill="#3f3d56"
        d="M235.967 505.958L456.967 505.958 456.967 443.958 235.967 443.958 171.967 474.958 235.967 505.958z"
      />
      <path
        d="M177.128 472.458L171.967 474.958 235.967 505.958 456.967 505.958 456.967 500.958 235.967 500.958 177.128 472.458z"
        opacity="0.1"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M467.967 373.958H505.967V411.958H467.967z"
      />
      <circle
        cx="314.967"
        cy="474.958"
        r="19"
        fill={get(theme, 'palette.primary.main')}
      />
      <circle
        cx="399.967"
        cy="390.958"
        r="11"
        fill="#2f2e41"
      />
      <circle
        cx="399.967"
        cy="472.958"
        r="11"
        fill="#2f2e41"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M438.483 710.315c0 22.469-13.356 30.314-29.833 30.314q-.573 0-1.144-.013a48.69 48.69 0 01-2.266-.104c-14.87-1.052-26.424-9.3-26.424-30.197 0-21.625 27.634-48.912 29.71-50.932l.004-.003.12-.117s29.833 28.584 29.833 51.052z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill="#3f3d56"
        d="M407.563 737.192l10.911-15.247-10.939 16.92-.029 1.751a48.69 48.69 0 01-2.266-.104l1.176-22.479-.01-.174.02-.033.111-2.123-10.966-16.963 11.001 15.37.025.451.89-16.984-9.39-17.528 9.504 14.547.925-35.213.004-.12v.116l-.155 27.768 9.347-11.008-9.385 13.4-.247 15.207 8.727-14.595-8.764 16.832-.138 8.454 12.67-20.312-12.717 23.262z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill="#575a89"
        d="M899.524 798.893s-14.16 11.923-21.612 11.178-21.61-.745-15.65 7.452 30.555 12.669 40.988 13.414 33.534 1.49 32.044-2.98-2.981-25.338-8.943-26.828-26.827-2.236-26.827-2.236zM980.789 787.186s-10.434 15.29-17.812 16.576-21.016 5.09-13.07 11.383 32.834 3.991 43.083 1.905 32.701-7.577 30.064-11.483-9.68-23.604-15.824-23.437-26.441 5.056-26.441 5.056z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill="#2f2e41"
        d="M891.23 568.517s-9.592 65.684-7.356 77.607 3.726 30.554 3.726 30.554-1.49 15.65 0 23.102 10.433 78.247 10.433 79.737-1.49 5.962 0 9.688 4.471.745 0 6.707-1.49 7.452-.745 7.452 29.808 2.981 31.299 0-.745-3.726 0-7.452 1.49-4.471 2.98-5.216 4.472.745 2.236-2.981-7.452-8.198-5.961-10.433-.745-18.63-.745-18.63-1.49-36.516-2.236-58.872 6.707-45.458 6.707-45.458a85.264 85.264 0 0019.375 49.929c19.376 23.101 29.064 66.324 29.064 66.324s4.47 6.707 2.235 12.668-8.942 2.236-2.235 5.962 29.808-1.49 32.044-3.726-2.981-5.216-1.49-11.178 7.452-4.471 2.235-7.452-6.707-2.236-6.707-5.217-13.414-67.814-34.28-86.444l-1.49-67.815s18.63-47.694-.745-52.165-78.345 13.309-78.345 13.309z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill="#a0616a"
        d="M904.74 392.006s12.669 31.3 9.688 35.77 36.515-14.904 36.515-14.904-12.668-31.299-12.668-35.025-33.535 14.16-33.535 14.16z"
        transform="translate(-112.517 -63.021)"
      />
      <circle
        cx="801.539"
        cy="309.237"
        r="27.573"
        fill="#a0616a"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M950.943 400.204s-19.375-2.236-29.063 9.688-11.178 14.904-11.178 14.904-23.102 37.26-18.63 55.89a53.906 53.906 0 012.98 5.217c1.49 2.981 1.49 44.713 0 51.42s-2.98 8.943-1.49 18.63-11.924 25.338 3.726 28.318 27.573.746 32.79-5.961.745-11.178 17.14-11.178 26.827-11.179 26.827-11.179-8.197-8.942-5.217-10.433 0-5.216-1.49-8.942-1.49-6.707.745-6.707.745-1.49-.745-5.217 26.082-67.814 9.688-86.444-24.592-29.064-24.592-29.064 2.98-11.178-1.49-8.942z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill="#a0616a"
        d="M908.466 470.999v32.79L884.62 582.78s-15.65 40.987 0 40.987 14.811-39.12 14.811-39.12l33.628-59.248s13.414-46.949 11.924-50.675-36.516-3.726-36.516-3.726z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M921.135 422.56s-22.357 8.943-16.395 32.79 0 23.101 0 23.101 30.554-2.98 40.242 3.726l2.235-8.942s8.943-51.42-26.082-50.675z"
        transform="translate(-112.517 -63.021)"
      />
      <path
        fill="#2f2e41"
        d="M904.64 351.559a11.001 11.001 0 01-10.334-1.608c-2.809-2.315-4-6.585-2.348-9.828 1.427-2.8 4.479-4.337 7.406-5.483a68.83 68.83 0 0120.17-4.554c3.297-.235 6.76-.197 9.701 1.309 1.456.745 2.72 1.82 4.162 2.592 2.65 1.42 5.735 1.735 8.6 2.648a21.526 21.526 0 0113.155 12.093 25.939 25.939 0 011.62 6.002 33.435 33.435 0 01-1.738 16.738c-3.177 8.458-9.84 15.852-10.216 24.88-4.078-2.96-5.59-8.3-8.858-12.134a16.503 16.503 0 00-9.419-5.463 5.246 5.246 0 01-2.807-1.024 4.52 4.52 0 01-1.026-2.392l-2.79-13.174c-.568-2.681-1.285-5.605-3.49-7.234a10.558 10.558 0 00-4.547-1.602 71.34 71.34 0 00-11.65-1.062"
        transform="translate(-112.517 -63.021)"
      />
    </svg>
  );
};
