import React from 'react';
import { Location } from '@reach/router';
import PropTypes from 'prop-types';
import { isCheckout } from './HideOnCheckout';

const HeaderMenuItems = ({ children }) => {
  const items = [
    {
      label: 'Vendors',
      to: '/vendors',
      visible: true,
    },
    {
      label: 'Services',
      to: '/services',
      visible: true,
    },
    {
      label: 'Company',
      to: '/company',
      visible: true,
    },
    {
      label: 'Blog',
      to: '/blog',
      visible: true,
    },
    {
      label: 'Contact',
      to: '/contact',
      visible: true,
    },
  ];

  return (
    <Location>
      {({ location: { pathname } }) =>
        children(isCheckout(pathname) ? [] : items)
      }
    </Location>
  );
};

HeaderMenuItems.propTypes = {
  children: PropTypes.func.isRequired,
};

export default HeaderMenuItems;
