import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { isObject } from 'lodash';
import axios from 'axios';

const useStyle = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.down('md')]: {
      marginTop: `-${theme.spacing(3)}`,
    },

    '& input': {
      backgroundColor: theme.palette.background.paper,
    },

    '& svg': {
      fill: theme.palette.background.paper,
    },
  },
  btn: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  label: {
    color: theme.palette.background.paper,
    fontSize: 'small',
  },
}));

export const SUCCESS_MSG =
  "Thank you for subscribing! You'll receive a message from us to confirm your email address shortly.";

export const ERROR_MSG =
  'We ran into difficulties subscribing you to our mailing lists. If this issue persists, contact our support team.';

export const TermsAndConditions = () => {
  const cls = useStyle();

  return (
    <Box mt={1}>
      <FormControlLabel
        classes={cls}
        control={
          <Checkbox color="inherit" name="optin" required />
        }
        label="I agree to receiving marketing communications from Gentek"
      />
    </Box>
  );
};

const FooterNewsletter = () => {
  const cls = useStyle();
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { contentfulCallToAction } = useStaticQuery(graphql`
    {
      contentfulCallToAction(
        contentful_id: { eq: "5DVLvOohHCIKu6VJ7pIMDm" }
      ) {
        title
        description {
          description
        }
      }
    }
  `);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    return axios
      .post('/mailchimp', Object.fromEntries(formData))
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const renderForm = () => {
    if (success)
      return (
        <Alert severity="success">{SUCCESS_MSG}</Alert>
      );

    if (error)
      return <Alert severity="error">{ERROR_MSG}</Alert>;

    return (
      <form className={cls.form} onSubmit={handleSubmit}>
        <TextField
          id="newsletter-name"
          fullWidth
          label="Name"
          name="name"
          required
          variant="filled"
        />
        <TextField
          id="newsletter-email"
          fullWidth
          label="Email"
          name="email"
          type="email"
          required
          variant="filled"
        />
        <TermsAndConditions />
        <Box mt={1} className={cls.btn}>
          <Button
            color="inherit"
            variant="outlined"
            size="large"
            type="submit"
          >
            Subscribe
          </Button>
        </Box>
      </form>
    );
  };

  return isObject(contentfulCallToAction) ? (
    <Box
      bgcolor="secondary.main"
      color="secondary.contrastText"
      component="aside"
      py={6}
      px={1.5}
    >
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="space-between"
          spacing={5}
        >
          <Grid item xs={12} lg={5}>
            <Typography
              color="inherit"
              variant="h1"
              component="h3"
            >
              {contentfulCallToAction.title}
            </Typography>
            <Box mt={1} maxWidth="85%">
              <Typography color="inherit">
                {
                  contentfulCallToAction.description
                    ?.description
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            {renderForm()}
          </Grid>
        </Grid>
      </Container>
    </Box>
  ) : null;
};

export default FooterNewsletter;
