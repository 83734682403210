import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const DIMENSION = 60;

const useStyle = makeStyles((theme) => ({
  text: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '1.2238rem',
    textTransform: 'uppercase',
    height: DIMENSION,
    // centering with flag instead of box
    lineHeight: `calc(${theme.spacing(
      0.5,
    )} + ${DIMENSION}px)`,
    padding: 0,
  },
}));

const FooterIconFlag = () => (
  <Box
    display="flex"
    alignItems="center"
    position="relative"
  >
    <Box
      display="inline-block"
      p={0.5}
      height={DIMENSION}
      width={DIMENSION}
      style={{
        background: '#0c255e',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-2015 -2000 4030 4030"
      >
        <path
          d="M-90 2030l45-863a95 95 0 00-111-98l-859 151 116-320a65 65 0 00-20-73l-941-762 212-99a65 65 0 0034-79l-186-572 542 115a65 65 0 0073-38l105-247 423 454a65 65 0 00111-57l-204-1052 327 189a65 65 0 0091-27L0-2000l332 652a65 65 0 0091 27l327-189L546-458a65 65 0 00111 57l423-454 105 247a65 65 0 0073 38l542-115-186 572a65 65 0 0034 79l212 99-941 762a65 65 0 00-20 73l116 320-859-151a95 95 0 00-111 98l45 863z"
          fill="white"
        />
      </svg>
    </Box>
    <Box className={useStyle().text} ml={1} component="h4">
      Your Canada Expert
    </Box>
  </Box>
);

export default FooterIconFlag;
