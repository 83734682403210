import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useAuth } from 'q3-ui-permissions';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'q3-ui-locale';
import { AddressLine } from 'q3-ui/src/address';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import LoyaltyTag from '@material-ui/icons/Loyalty';
import LocalOffer from '@material-ui/icons/LocalOffer';
import AttachMoney from '@material-ui/icons/AttachMoney';
import AddBoxIcon from '@material-ui/icons/AddBox';
import List, { ListItem } from 'q3-ui/src/list';
import Avatar from 'q3-ui/src/avatar';
import { string } from '@lolly-labs/q3-ui-helpers';
import { filterSkuless } from './helpers';
import { COLLECTIONS } from './constants';

export const OrderItemKitDescription = ({ components }) => {
  const { t } = useTranslation('labels');
  const { HideByField } = useAuth(
    COLLECTIONS.ORDERS.COLLECTION_NAME,
  );

  return (
    <Box style={{ fontSize: '1rem' }}>
      <List>
        {components.map((item) => {
          const {
            id,
            sku,
            excerpt,
            quantity,
            price,
            currencyDefault,
            photo,
          } = item;
          return (
            <React.Fragment key={id}>
              <ListItem
                title={sku}
                description={excerpt}
                icon={() =>
                  React.createElement(Avatar, {
                    word: sku,
                    imgSrc: photo,
                  })
                }
              />
              <HideByField
                op="Read"
                path="price"
                subfield="items"
              >
                <Box component="li" ml={7} mb={1}>
                  <AddressLine
                    icon={AddBoxIcon}
                    label={t('quantity')}
                  >
                    {quantity}
                  </AddressLine>

                  <AddressLine
                    icon={LoyaltyTag}
                    label={t('price')}
                  >
                    {string.toPrice(get(price, 'custom'))}
                  </AddressLine>
                  <HideByField
                    op="Update"
                    path="priceOverride"
                    subfield="items"
                  >
                    <AddressLine
                      icon={LocalOffer}
                      label={t('originalResellerPrice')}
                    >
                      {string.toPrice(
                        get(price, 'reseller'),
                      )}
                    </AddressLine>
                    <AddressLine
                      icon={AttachMoney}
                      label={t('defaultCurrency')}
                    >
                      {currencyDefault}
                    </AddressLine>
                  </HideByField>
                </Box>{' '}
              </HideByField>
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
};

const ExpansionKits = ({ item, ...rest }) => {
  const components = filterSkuless(
    get(item, 'bucket.components', []),
  );

  return components.length ? (
    <CollapsiblePanel title="components" {...rest}>
      <OrderItemKitDescription components={components} />
    </CollapsiblePanel>
  ) : null;
};

ExpansionKits.propTypes = {
  components: PropTypes.arrayOf({
    sku: PropTypes.string,
    excerpt: PropTypes.string,
  }).isRequired,
};

export default ExpansionKits;
