import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { Form, Field } from 'q3-ui-forms/src/builders';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import NumberedList from './NumberedList';
import OrderLock from './OrderLock';
import { COLLECTIONS } from './constants';
import useOrderAuth from './useOrderAuth';

const getComments = (item) =>
  get(item, 'bucket.ZZ_COMMENTS', []);

const getEllaboration = (item) =>
  get(item, 'ellaboration', '');

export const OrderItemCommentsForm = ({
  onSubmit,
  ellaboration,
  product,
  quantity,
  id,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{
      ellaboration,
      id,
      product,
      quantity,
    }}
    collectionName={COLLECTIONS.ORDERS.COLLECTION_NAME}
  >
    <Field
      name="ellaboration"
      under="items"
      type="text"
      multiline
      rows={5}
      required
      xl={12}
      lg={12}
    />
  </Form>
);

OrderItemCommentsForm.propTypes = {
  id: PropTypes.string.isRequired,
  ellaboration: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const OrderItemCommentsList = ({ comments }) => (
  <NumberedList>
    {comments.map((comment) => (
      <Typography component="li" key={comment}>
        {comment}
      </Typography>
    ))}
  </NumberedList>
);

OrderItemCommentsList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const OrderItemComments = ({ item, update, ...rest }) => {
  const comments = getComments(item);
  const ellaboration = getEllaboration(item);
  const { HideByField } = useOrderAuth();

  const fieldProps = {
    subfield: 'items',
    path: 'ellaboration',
  };

  return comments.length ||
    get(item, 'bucket.sku') === 'ZZCOMMENT' ? (
    <HideByField op="Read" {...fieldProps}>
      <CollapsiblePanel
        title="comments"
        warning={!ellaboration}
        description={
          !ellaboration ? 'informationMayBeExpected' : null
        }
        {...rest}
      >
        <OrderItemCommentsList comments={comments} />
        {update && (
          <HideByField op="Update" {...fieldProps}>
            <OrderLock>
              <OrderItemCommentsForm
                onSubmit={update}
                ellaboration={ellaboration}
                {...item}
              />
            </OrderLock>
          </HideByField>
        )}
      </CollapsiblePanel>
    </HideByField>
  ) : null;
};

OrderItemComments.propTypes = {
  item: PropTypes.shape({
    ellaboration: PropTypes.string,
    bucket: PropTypes.shape({
      ZZ_COMMENTS: PropTypes.array,
    }),
  }).isRequired,
  update: PropTypes.func,
};

OrderItemComments.defaultProps = {
  update: null,
};

export default OrderItemComments;
