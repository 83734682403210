import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import { navigate, useLocation } from '@reach/router';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepButton from '@material-ui/core/StepButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  SHIPPING,
  SHIPPING_OPTIONS,
  OPTIONS,
  CONFIRM,
} from '../containers/CheckoutNavigation';

export const isDone = (a, b) => b < a;

export const matchLocationPathToIndex = (v) => {
  if (typeof v !== 'string') return 0;

  const split = v.split('/');
  switch (split[split.length - 1]) {
    case 'delivery':
      return 1;
    case 'options':
      return 2;
    case 'confirm':
      return 3;
    default:
      return 0;
  }
};

const HeaderProgressBar = () => {
  const width = 650;

  const { t } = useTranslation('labels');
  const { href, pathname } = useLocation();
  const matches = useMediaQuery(`(min-width:${width}px)`);

  const activeStep = matchLocationPathToIndex(href);
  const orientation = matches ? 'horizontal' : 'vertical';

  return pathname.includes('checkout') ? (
    <Stepper
      elevation={0}
      activeStep={activeStep}
      orientation={orientation}
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        width,
      }}
    >
      {Object.entries({
        address: SHIPPING,
        shippingOption: SHIPPING_OPTIONS,
        options: OPTIONS,
        confirm: CONFIRM,
      }).map(([label, path], i) => (
        <Step key={path} value={i}>
          <StepButton
            completed={isDone(activeStep, i)}
            onClick={() => {
              navigate(path);
            }}
          >
            {t(label)}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  ) : null;
};

export default HeaderProgressBar;
