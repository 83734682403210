import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';

export default () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 909 591.27"
    >
      <path
        fill={get(theme, 'palette.primary.light')}
        d="M452.095 744.333H185.228s-5.41-79.339 26.596-80.24 28.4 35.161 68.52-14.426 88.806-46.882 95.117-17.58-12.172 52.742 21.637 45.53 82.495 11.72 54.997 66.716z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#f2f2f2"
        d="M314.156 744.351l-.9-.036c2.1-52.236 13.04-85.798 21.848-104.755 9.563-20.583 18.778-28.915 18.87-28.997l.6.673c-.092.081-9.2 8.331-18.686 28.775-8.759 18.876-19.639 52.3-21.732 104.34zM410.742 744.459l-.865-.252c10.422-35.771 34.964-58.357 35.21-58.58l.606.667c-.244.222-24.603 22.647-34.95 58.165zM227.26 744.395l-.893-.124c3.536-25.501-2.85-45.287-8.832-57.394-6.476-13.109-13.865-20.268-13.938-20.34l.623-.65c.075.072 7.579 7.342 14.124 20.591 6.036 12.22 12.484 32.189 8.917 57.917z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#cfcce0"
        d="M488.314 745.54h-331.87l-.077-1.125c-.19-2.797-4.424-68.764 16.95-92.301 4.778-5.262 10.534-8.024 17.107-8.209 13.933-.396 22.689 4.823 29.75 9.012 13.311 7.896 22.105 13.113 54.076-26.403 34.635-42.808 72.005-52.018 93.488-46.897 13.88 3.31 23.446 12.663 26.246 25.662 2.652 12.31 1.807 23.875 1.129 33.169-.726 9.943-1.3 17.797 2.784 21.356 3.41 2.97 10.214 3.24 21.413.85 22.78-4.86 54.566-.701 70.52 19.912 8.584 11.09 15.345 31.25-1.183 64.306zm-329.605-2.413H486.82c12.317-24.988 12.701-46.098 1.103-61.084-14.636-18.91-45.341-23.888-68.11-19.03-12.167 2.597-19.418 2.168-23.5-1.39-4.994-4.351-4.381-12.736-3.606-23.35.667-9.14 1.498-20.515-1.08-32.486-2.597-12.055-11.508-20.738-24.449-23.823-20.797-4.962-57.123 4.132-91.053 46.067-33.282 41.135-43.307 35.19-57.18 26.96-7.15-4.24-15.267-9.049-28.452-8.675-5.893.166-11.07 2.663-15.39 7.42-19.21 21.153-16.865 80.473-16.394 89.391z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#f2f2f2"
        d="M816.24 575.544l-96.549-16.787c17.258-35.794 25.144-71.159 18.406-105.855l96.549 16.787a214.51 214.51 0 00-18.406 105.855z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#3f3d56"
        d="M757.447 480.471H808.217V482.832H757.447z"
        transform="rotate(9.864 1604.525 -438.608)"
      />
      <path
        fill="#3f3d56"
        d="M755.829 489.777H806.5989999999999V492.138H755.829z"
        transform="rotate(9.864 1602.907 -429.302)"
      />
      <path
        fill="#3f3d56"
        d="M754.211 499.083H804.981V501.444H754.211z"
        transform="rotate(9.864 1601.255 -419.99)"
      />
      <path
        fill="#3f3d56"
        d="M752.593 508.389H803.3629999999999V510.75H752.593z"
        transform="rotate(9.864 1599.67 -410.69)"
      />
      <path
        fill="#3f3d56"
        d="M750.975 517.695H801.745V520.056H750.975z"
        transform="rotate(9.864 1598.053 -401.384)"
      />
      <path
        fill="#3f3d56"
        d="M749.356 527.001H800.126V529.362H749.356z"
        transform="rotate(9.864 1596.435 -392.078)"
      />
      <path
        fill="#3f3d56"
        d="M747.738 536.307H798.508V538.668H747.738z"
        transform="rotate(9.864 1594.817 -382.772)"
      />
      <path
        fill="#3f3d56"
        d="M746.12 545.613H796.89V547.974H746.12z"
        transform="rotate(9.864 1593.199 -373.466)"
      />
      <path
        fill="#f2f2f2"
        d="M859.24 609.544l-96.549-16.787c17.258-35.794 25.144-71.159 18.406-105.855l96.549 16.787a214.51 214.51 0 00-18.406 105.855z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#3f3d56"
        d="M800.447 514.471H851.217V516.832H800.447z"
        transform="rotate(9.864 1647.525 -404.608)"
      />
      <path
        fill="#3f3d56"
        d="M798.829 523.777H849.5989999999999V526.138H798.829z"
        transform="rotate(9.864 1645.907 -395.302)"
      />
      <path
        fill="#3f3d56"
        d="M797.211 533.083H847.981V535.444H797.211z"
        transform="rotate(9.864 1644.255 -385.99)"
      />
      <path
        fill="#3f3d56"
        d="M795.593 542.389H846.3629999999999V544.75H795.593z"
        transform="rotate(9.864 1642.67 -376.69)"
      />
      <path
        fill="#3f3d56"
        d="M793.975 551.695H844.745V554.056H793.975z"
        transform="rotate(9.864 1641.053 -367.384)"
      />
      <path
        fill="#3f3d56"
        d="M792.356 561.001H843.126V563.362H792.356z"
        transform="rotate(9.864 1639.435 -358.078)"
      />
      <path
        fill="#3f3d56"
        d="M790.738 570.307H841.508V572.668H790.738z"
        transform="rotate(9.864 1637.817 -348.772)"
      />
      <path
        fill="#3f3d56"
        d="M789.12 579.613H839.89V581.974H789.12z"
        transform="rotate(9.864 1636.199 -339.466)"
      />
      <path
        fill="#3f3d56"
        d="M885.558 744.245H713.342l-.033-.965-8.223-235.176h188.727zm-170.285-2h168.353l8.117-232.14H707.156z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#3f3d56"
        d="M730.627 577.241L577.272 577.241 569.899 366.378 738 366.378 730.627 577.241z"
      />
      <g fill="#fff" opacity="0.2">
        <path d="M595.824 397.31H608.923V559.407H595.824z" />
        <path d="M647.4 397.31H660.499V559.407H647.4z" />
        <path d="M698.976 397.31H712.075V559.407H698.976z" />
      </g>
      <path
        fill="#3f3d56"
        d="M883.812 371.59H916.559V623.741H883.812z"
        transform="rotate(-57.699 687.327 552.545)"
      />
      <path
        fill="#3f3d56"
        d="M930.605 449.548a38.46 38.46 0 00-58.62 38.07l10.195 6.446a30.344 30.344 0 1128.98 18.321l10.196 6.446a38.46 38.46 0 009.249-69.283z"
        transform="translate(-145.5 -154.365)"
      />
      <path fill="#3f3d56" d="M0 588.5H909V590.5H0z" />
      <path
        fill="#ffb8b8"
        d="M625.5 402.865s39 29 23 34-36-17-35-23 12-11 12-11z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#575a89"
        d="M446 211.5L486 248.5 470 265.5 443 245.5 446 211.5z"
      />
      <path
        fill={get(theme, 'palette.primary.light')}
        d="M681.008 517.804l-82.468-52.939c29.795-26.291 50.787-55.824 58.042-90.417l82.468 52.94a214.51 214.51 0 00-58.042 90.416z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#3f3d56"
        d="M661.269 417.125H712.039V419.486H661.269z"
        transform="rotate(32.698 877.013 93.124)"
      />
      <path
        fill="#3f3d56"
        d="M656.166 425.074H706.936V427.435H656.166z"
        transform="rotate(32.698 871.91 101.073)"
      />
      <path
        fill="#3f3d56"
        d="M651.064 433.023H701.834V435.384H651.064z"
        transform="rotate(32.698 866.808 109.021)"
      />
      <path
        fill="#3f3d56"
        d="M645.961 440.971H696.731V443.332H645.961z"
        transform="rotate(32.698 861.705 116.97)"
      />
      <path
        fill="#3f3d56"
        d="M640.859 448.92H691.629V451.281H640.859z"
        transform="rotate(32.698 856.603 124.919)"
      />
      <path
        fill="#3f3d56"
        d="M635.756 456.869H686.526V459.23H635.756z"
        transform="rotate(32.698 851.5 132.868)"
      />
      <path
        fill="#3f3d56"
        d="M630.653 464.817H681.423V467.178H630.653z"
        transform="rotate(32.698 846.398 140.816)"
      />
      <path
        fill="#3f3d56"
        d="M625.551 472.766H676.321V475.127H625.551z"
        transform="rotate(32.698 841.295 148.765)"
      />
      <path
        fill="#ffb8b8"
        d="M303.165 490.416L287.987 522.348 330.446 532.508 329.263 501.725 303.165 490.416z"
      />
      <path
        fill="#ffb8b8"
        d="M455 533.5L460 568.5 501 553.5 483 528.5 455 533.5z"
      />
      <path
        fill="#2f2e41"
        d="M509.692 358.27s-6.283 40.232-4.573 59.365-5.032 99.858-5.032 99.858-30.153 32.8-36.997 70.258a312.689 312.689 0 01-21.47 68.557l35.528 11.564 70.407-133.297s50.491-110.506 45.474-121.033-12.884-52.943-12.884-52.943z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#2f2e41"
        d="M442.62 668.37s-.044-18.027-6.404-10.246-27.126 22.807-14.898 33.317 83.644 67.474 87.487 50.824c2.134-9.244-33.484-71.638-33.484-71.638s-11.893.745-12.718 3.797-19.984-6.054-19.984-6.054z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#ffb8b8"
        d="M424 40.5L397 71.5 424 99.5 449 61.5 424 40.5z"
      />
      <path
        fill="#ffb8b8"
        d="M615.5 196.865a27.012 27.012 0 11-.49-5.11 27.002 27.002 0 01.49 5.11z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#2f2e41"
        d="M570.715 176.423c9.37-4.354 20.413-3.798 30.297-.785 5.607 1.71 11.14 4.287 15.065 8.641s5.943 10.77 3.903 16.264l-8.173-6.18-.749 5.447c-5.393-8.415-15.638-12.07-25.14-15.171-2.972-.97-6.917-1.632-8.63.982-.723 1.102-.805 2.54-1.573 3.61-1.44 2.006-4.45 1.83-6.653 2.942-2.064 1.042-3.314 3.17-4.427 5.195s-.62 4.865-2.635 5.997c-8.084 4.54-3.318-12.945-2-16 2.603-6.034 4.773-8.18 10.715-10.942zM493 415.365s17 37 29 52 51 86 51 86-7 44 8 79a312.69 312.69 0 0120 69l36-10-15-150s-19-120-29-126-40-37-40-37z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#575a89"
        d="M576.5 251.865s-17-45-40-31-41 139-41 139-21 57 0 63 38 14 41 3 1-30 6-20 3 22 13 22 49-5 48-12-27-164-27-164z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#ffb8b8"
        d="M493.5 428.865s-13 39 5 39 15.399-39.374 15.399-39.374z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#2f2e41"
        d="M608.5 710.865s-10-15-11-5-10 34 6 36 107 10 101-6c-3.331-8.883-67.5-41.195-67.5-41.195s-9.5 7.195-8.5 10.195-20 6-20 6z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#575a89"
        d="M633 203.365s-4.74 4.07-10.89-3.98a39.017 39.017 0 01-4.11-7.02 27.46 27.46 0 00-18.56-7.97q-.705-.03-1.44-.03c-13 0-16-9-16-9l-21 7s3-28 32-28 30 24 30 24 23 9 10 25z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        d="M622.11 199.385a39.017 39.017 0 01-4.11-7.02 27.46 27.46 0 00-18.56-7.97c7.31-2.29 20.02-4.95 25.56 1.97 5.1 6.37.86 10.71-2.89 13.02z"
        opacity="0.2"
        transform="translate(-145.5 -154.365)"
      />
      <path
        fill="#575a89"
        d="M537.5 228.865s-27-5-34 19-22 95-22 95l9 73-1 18h29v-19c0-2 4-80 4-80l33-75s7-24-18-31z"
        transform="translate(-145.5 -154.365)"
      />
      <path
        d="M410.5 116L377.5 181 373.451 274.126 384.5 180 410.5 116z"
        opacity="0.2"
      />
      <path
        d="M450.525 215.686L458.019 254.094 454.272 219.152 450.525 215.686z"
        opacity="0.2"
      />
      <path
        d="M365.5 305.5L408.5 366.5 404 375.5 365.5 305.5z"
        opacity="0.2"
      />
    </svg>
  );
};
