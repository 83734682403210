import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'q3-ui/src/collapsiblePanel/accordion';
import { get } from 'lodash';
import { Box } from '@material-ui/core';
import OrderItemAlerts from './OrderItemAlerts';
import OrderItemCustoms from './OrderItemCustoms';
import OrderItemComments from './OrderItemComments';
import OrderItemDiscounts from './OrderItemDiscounts';
import OrderItemDescription from './OrderItemDescription';
// eslint-disable-next-line
import OrderItemInformation from './OrderItemInformation';
import OrderItemFutureShipDates from './OrderItemFutureShipDates';
import OrderItemCompanion from './OrderItemCompanion';
import OrderItemKit from './OrderItemKit';
import OrderItemRebates from './OrderItemRebates';
import OrderItemRestricted from './OrderItemRestricted';
import OrderItemProvisioning from './OrderItemProvisioning';
import OrderItemValidity from './OrderItemValidity';
import { useLock } from './OrderLock';

export const getTitle = (item) => {
  const renewal = get(item, 'bucket.daysUntilRenewal');
  const sku = get(item, 'bucket.sku');

  if (renewal) {
    if (sku === '3CX_SKU')
      return `${sku} with ${
        renewal / 365
      } Additional Year(s)`;

    return `${sku} (Renews in ${renewal} days)`;
  }

  return sku;
};

export const AccordionBuilder = ({
  panels,
  panelProps,
}) => (
  <Box
    bgcolor="background.paper"
    mb={1}
    style={{
      marginLeft: '-8px',
      marginRight: '-8px',
    }}
  >
    <Accordion>
      {panels.map((El) =>
        React.createElement(El, panelProps),
      )}
    </Accordion>
  </Box>
);

AccordionBuilder.propTypes = {
  panels: PropTypes.arrayOf(PropTypes.node).isRequired,
  panelProps: PropTypes.shape({
    // eslint-disable-next-line
    item: PropTypes.object,
    update: PropTypes.func,
  }).isRequired,
};

const Ab = React.memo(AccordionBuilder);

const OrderItemAccordionBuilder = ({ children }) => {
  return children({
    disableEditor: true,
    disableMultiselect: true,
    disableRemove: useLock(),
    root: 'items',

    cardProps: {
      title: getTitle,
      describe: OrderItemDescription,
    },

    renderNestedTableRow: (item, ctx) => (
      <Ab
        panels={[
          OrderItemAlerts,
          OrderItemCompanion,
          OrderItemCustoms,
          OrderItemRestricted,
          OrderItemDiscounts,
          OrderItemInformation,
          OrderItemKit,
          OrderItemRebates,
          OrderItemComments,
          OrderItemProvisioning,
          OrderItemFutureShipDates,
          OrderItemValidity,
        ]}
        panelProps={{
          // eslint-disable-next-line
          update: ctx.patch ? ctx.patch(item.id) : null,
          item,
        }}
      />
    ),
  });
};

OrderItemAccordionBuilder.propTypes = {
  children: PropTypes.func.isRequired,
};

export default OrderItemAccordionBuilder;
