import axios from 'axios';
import React from 'react';
import { get, map } from 'lodash';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { AuthContext } from 'q3-ui-permissions';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import { WEBSITE, WEBSITE_RESELLER } from '../constants';

export const mapDescription = (notices, lang) =>
  map(notices, (notice) => {
    const copy = { ...notice };

    if (String(lang).includes('fr'))
      copy.description = notice.descriptionFr;
    return copy;
  });

export const pickLocation = (predicate) =>
  predicate ? WEBSITE_RESELLER : WEBSITE;

const HeaderNotice = () => {
  const [notices, setNotices] = React.useState([]);
  const { state } = React.useContext(AuthContext);

  React.useEffect(() => {
    const id = get(state, 'profile.id');
    const lang = get(state, 'profile.lang');
    const location = pickLocation(Boolean(id));

    axios
      .create({})
      .get(
        `${COLLECTIONS.NOTICES.ENDPOINT}?location=${location}&show=true&active=true`,
      )
      .then(({ data }) => {
        if (data && data.notices && data.notices.length)
          setNotices(mapDescription(data.notices, lang));
      });
  }, [state.profile]);

  return notices.map((notice) => (
    <Box
      bgcolor="secondary.main"
      color="secondary.contrastText"
      key={notice.id}
      position="relative"
      role="alert"
      p={1}
    >
      <Box component="small" style={{ fontWeight: 'bold' }}>
        {notice.description}{' '}
        <Link
          color="inherit"
          href={notice.url}
          style={{ marginLeft: '1rem' }}
        >
          More
        </Link>
      </Box>
    </Box>
  ));
};

export default HeaderNotice;
