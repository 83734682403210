import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Box from '@material-ui/core/Box';

const recaptchaRef = React.createRef();

export const handleCapt = (next) => (values) => {
  return recaptchaRef.current
    .execute()
    .then(() => {
      return next(values);
    })
    .catch(() => {
      const e = new Error('Validation');
      e.message = 'Missing reCaptcha';
      throw e;
    });
};

export default () => (
  <Box zIndex="1000" position="fixed">
    <ReCAPTCHA
      style={{ visibility: 'hidden' }}
      ref={recaptchaRef}
      sitekey="6LcNfvgZAAAAAJn1NUz9fBv7_7uHLMh4yGUxiBfS"
      size="invisible"
    />
  </Box>
);
