import React from 'react';
import PropTypes from 'prop-types';
import DeleteForever from '@material-ui/icons/DeleteForever';
import {
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
} from '@material-ui/core';
import { string } from '@lolly-labs/q3-ui-helpers';
import { size, get, filter, map } from 'lodash';
import { useTranslation } from 'q3-ui-locale';

export const hasProgramValue = (xs) =>
  size(get(xs, 'claim.program'));

export const checkForPrograms = (xs) =>
  size(filter(xs, hasProgramValue));

export const printSymbol = (sym, v) => {
  if (sym === '$') return `${sym}${v}`;
  return `${v}${sym}`;
};

export const printNumberOfClaimbacks = (xs) => {
  const n = checkForPrograms(xs);
  const pluralized = n > 1 ? 's' : '';

  return n
    ? `Contains ${n} vendor claim-back${pluralized}`
    : '';
};

export const ensureArray = (xs) =>
  Array.isArray(xs) && xs.length ? xs : [];

export const printEndUser = (xs) =>
  xs ? `for ${xs}` : '--';

const VendorClaimTableRow = ({
  program,
  extendedValue,
  currency,
  kind,
  value,
  endUser,
  onClick,
  id,
}) => (
  <TableRow>
    <TableCell>
      {program} ({printSymbol(kind, value)})
    </TableCell>
    <TableCell>
      {string.toPrice(extendedValue)} ({currency}){' '}
    </TableCell>
    <TableCell>{printEndUser(endUser)}</TableCell>
    <TableCell>
      <IconButton
        disabled={!onClick}
        onClick={() => {
          try {
            return onClick(id);
          } catch (e) {
            return null;
          }
        }}
      >
        <DeleteForever />
      </IconButton>
    </TableCell>
  </TableRow>
);

VendorClaimTableRow.defaultProps = {
  program: undefined,
  currency: 'CAD',
  extendedValue: 0,
  kind: '$',
  value: 0,
  endUser: undefined,
  onClick: null,
};

VendorClaimTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  program: PropTypes.string,
  extendedValue: PropTypes.number,
  currency: PropTypes.string,
  endUser: PropTypes.string,
  kind: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
};

const VendorClaimTable = ({ data, ...rest }) => {
  const { t } = useTranslation('labels');
  const rows = filter(data, hasProgramValue);

  return size(rows) ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th">
            {t('program')}
          </TableCell>
          <TableCell component="th">
            {t('claim.extendedValue')}
          </TableCell>
          <TableCell component="th">
            {t('claim.endUser')}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {map(rows, (dis) => (
          <VendorClaimTableRow
            key={`${get(dis, 'claim.program')}`}
            {...rest}
            {...dis.claim}
          />
        ))}
      </TableBody>
    </Table>
  ) : null;
};

VendorClaimTable.defaultProps = {
  data: [],
};

VendorClaimTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      program: PropTypes.string,
    }),
  ),
};

export default VendorClaimTable;
