import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import get from 'lodash.get';

export default (props) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1010.892"
      height="727.284"
      viewBox="0 0 1010.892 727.284"
      {...props}
    >
      <path
        fill="#3f3d56"
        d="M1105.446 813.642q-78.133-11.444-150.282-19.73l19.02-30.778c-6.412-1.894-34.585 15.652-34.585 15.652l24.967-89.48c-32.248 3.106-48.657 94.513-48.657 94.513l-36.025-31.096 17.438 34.854c-147.212-15.196-274.35-21.165-381.29-21.854l16.279-26.342c-6.412-1.895-34.585 15.651-34.585 15.651l24.967-89.48c-32.248 3.107-48.657 94.513-48.657 94.513L438.01 728.97l18.47 36.916c-89.651 1.086-162.761 5.871-219.191 11.45 16.467-41.077 72.393-79.96 72.393-79.96-42.707 10.437-65.112 28.152-76.82 44.71-.895-50.817 5.126-144.999 49.856-249.094 0 0-88.296 160.409-77.048 268.408l1.344 19.175c-74.836 8.625-112.46 17.784-112.46 17.784z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#3f3d56"
        d="M632.08 115.488v648.2a29.14 29.14 0 01-29.13 29.11H339.8a29.08 29.08 0 01-29.13-29.11v-648.2a29.127 29.127 0 0129.13-29.13h39.38v5.05a23.987 23.987 0 0023.98 23.99H537a23.79 23.79 0 0014.22-4.68 22.285 22.285 0 002.36-1.98 23.917 23.917 0 007.4-17.33v-5.05h41.97a29.121 29.121 0 0129.13 29.13z"
        transform="translate(-94.554 -86.358)"
      />
      <circle cx="289.426" cy="108" r="7" fill="#f2f2f2" />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M553.58 108.738a22.285 22.285 0 01-2.36 1.98 125.247 125.247 0 0080.86 60.19v-3.12a122.299 122.299 0 01-78.5-59.05zM493.77 115.398h-3.28a181.668 181.668 0 00141.59 113.05v-3.1a178.55 178.55 0 01-138.31-109.95zM433.35 115.398h-3.16c26.43 91.74 106.18 160.03 201.89 171.12v-3.05c-94.05-11.04-172.43-78.03-198.73-168.07z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#f2f2f2"
        d="M266.426 159.822L264.604 159.822 264.604 158 264.249 158 264.249 159.822 262.426 159.822 262.426 160.178 264.249 160.178 264.249 162 264.604 162 264.604 160.178 266.426 160.178 266.426 159.822z"
      />
      <path
        fill="#f2f2f2"
        d="M389.426 68.822L387.604 68.822 387.604 67 387.249 67 387.249 68.822 385.426 68.822 385.426 69.178 387.249 69.178 387.249 71 387.604 71 387.604 69.178 389.426 69.178 389.426 68.822z"
      />
      <path
        fill="#f2f2f2"
        d="M284.426 231.822L282.604 231.822 282.604 230 282.249 230 282.249 231.822 280.426 231.822 280.426 232.178 282.249 232.178 282.249 234 282.604 234 282.604 232.178 284.426 232.178 284.426 231.822z"
      />
      <path
        fill="#f2f2f2"
        d="M368.426 178.822L366.604 178.822 366.604 177 366.249 177 366.249 178.822 364.426 178.822 364.426 179.178 366.249 179.178 366.249 181 366.604 181 366.604 179.178 368.426 179.178 368.426 178.822z"
      />
      <path
        fill="#f2f2f2"
        d="M488.426 211.822L486.604 211.822 486.604 210 486.249 210 486.249 211.822 484.426 211.822 484.426 212.178 486.249 212.178 486.249 214 486.604 214 486.604 212.178 488.426 212.178 488.426 211.822z"
      />
      <circle cx="467.426" cy="125" r="4" fill="#ff6584" />
      <path
        fill="#e6e6e6"
        d="M418.68 305.158l-2.27.35c.71 4.54 1.27 9.18 1.68 13.77l2.29-.2c-.41-4.65-.98-9.33-1.7-13.92zM412.47 277.818l-2.2.67c1.33 4.4 2.52 8.91 3.53 13.4l2.25-.51c-1.03-4.54-2.24-9.1-3.58-13.56zM402.56 251.598l-2.09.96c1.92 4.19 3.72 8.49 5.35 12.79l2.15-.81c-1.65-4.35-3.46-8.71-5.41-12.94zM389.16 227.018l-1.94 1.23c2.47 3.88 4.84 7.9 7.04 11.93l2.02-1.1c-2.22-4.08-4.62-8.14-7.12-12.06zM372.5 204.488l-1.75 1.49c2.98 3.5 5.88 7.16 8.62 10.86l1.84-1.37c-2.76-3.74-5.69-7.44-8.71-10.98zM352.92 184.428l-1.53 1.71c3.44 3.07 6.81 6.3 10.02 9.59l1.65-1.61c-3.25-3.33-6.66-6.59-10.14-9.69zM330.8 167.208l-1.29 1.9c3.82 2.57 7.6 5.31 11.23 8.14l1.42-1.81c-3.68-2.86-7.5-5.63-11.36-8.23zM310.67 155.248v2.6c2.39 1.25 4.76 2.56 7.08 3.9l1.15-1.99q-4.05-2.34-8.23-4.51zM418.16 521.16c.374-2.235.715-4.518 1.016-6.784l2.279.302c-.304 2.291-.649 4.6-1.026 6.86zM411.752 548.14l2.197.685a210.794 210.794 0 003.708-13.532l-2.24-.53a206.088 206.088 0 01-3.665 13.377zM401.71 573.972l2.087.978a200.058 200.058 0 005.524-12.884l-2.145-.837a195.69 195.69 0 01-5.466 12.743zM388.199 598.205l1.928 1.252a205.47 205.47 0 007.255-12.01l-2.006-1.124a203.283 203.283 0 01-7.177 11.882zM371.482 620.344l1.743 1.51a213.118 213.118 0 008.836-10.91l-1.841-1.377a202.315 202.315 0 01-8.738 10.777zM351.907 639.99l1.515 1.728a206.062 206.062 0 0010.24-9.592l-1.626-1.63a210.913 210.913 0 01-10.129 9.493zM329.855 656.78l1.27 1.923a210.522 210.522 0 0011.438-8.112l-1.39-1.836a201.609 201.609 0 01-11.318 8.025zM319.16 666.008c-2.78 1.57-5.62 3.09-8.49 4.53v-2.58c2.48-1.26 4.94-2.59 7.35-3.94zM418.836 333.464l2.298-.042c.042 2.321.046 4.654.01 6.936l-2.299-.036c.035-2.256.032-4.563-.01-6.858z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#fff"
        d="M226.216 301.632h235.21a66 66 0 0166 66v8.736h-235.21a66 66 0 01-66-66v-8.736z"
      />
      <path
        fill="#f2f2f2"
        d="M476.87 791.88c-.23-.376-5.64-9.41-7.516-28.172-1.721-17.213-.615-46.227 14.432-86.699 28.506-76.67-6.569-138.533-6.927-139.15l1.73-1.003c.09.156 9.142 15.929 14.488 41.044a179.061 179.061 0 01-7.416 99.807c-28.457 76.54-7.3 112.773-7.084 113.13z"
        transform="translate(-94.554 -86.358)"
      />
      <circle
        cx="371.17"
        cy="434"
        r="13"
        fill={get(theme, 'palette.primary.main')}
      />
      <circle cx="412.17" cy="482" r="13" fill="#f2f2f2" />
      <circle
        cx="384.17"
        cy="514"
        r="13"
        fill={get(theme, 'palette.primary.main')}
      />
      <circle
        cx="418.17"
        cy="541"
        r="13"
        fill={get(theme, 'palette.primary.main')}
      />
      <circle cx="374.17" cy="583" r="13" fill="#f2f2f2" />
      <path
        fill="#f2f2f2"
        d="M484.724 792.358s-13-32 26-56zM468.736 791.777s-5.917-34.029-51.709-33.737z"
        transform="translate(-94.554 -86.358)"
      />
      <circle
        cx="322.426"
        cy="339"
        r="80"
        fill={get(theme, 'palette.primary.main')}
        opacity="0.2"
      />
      <circle
        cx="322.426"
        cy="339"
        r="56.338"
        fill={get(theme, 'palette.primary.main')}
        opacity="0.2"
      />
      <circle
        cx="322.426"
        cy="339"
        r="37.183"
        fill={get(theme, 'palette.primary.main')}
      />
      <path
        fill="#fff"
        d="M423.741 417.338a6.76 6.76 0 10-11.031 5.239l-2.49 17.429h13.521l-2.49-17.429a6.746 6.746 0 002.49-5.239z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#2f2e41"
        d="M790.661 139.658s-22.302-19.995-52.294 17.688-76.903 56.14-87.67 70.75c0 0 44.604-18.456 59.985-26.146s14.612-6.153 14.612-6.153-20.764 14.612-24.61 29.224-.768 26.916-7.69 41.528 132.274 9.997 132.274 9.997 13.843-23.84 9.997-48.45-1.538-85.362-44.604-88.438z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#ffb9b9"
        d="M748.479 202.56s4.85 33.947-15.934 41.568-13.163 18.012-13.163 18.012l31.175 15.241 33.254-10.391 11.085-16.627s-18.013-4.157-13.163-16.627 6.235-16.627 6.235-16.627z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        d="M748.479 202.56s4.85 33.947-15.934 41.568-13.163 18.012-13.163 18.012l31.175 15.241 33.254-10.391 11.085-16.627s-18.013-4.157-13.163-16.627 6.235-16.627 6.235-16.627z"
        opacity="0.1"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#ffb9b9"
        d="M835.77 299.55s-4.85 63.738-3.464 66.509 0 99.069 0 99.069 9.7 51.96-2.77 54.73-9.7-58.194-9.7-58.194l-12.47-80.364 1.386-79.67z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        d="M835.77 299.55s-4.85 63.738-3.464 66.509 0 99.069 0 99.069 9.7 51.96-2.77 54.73-9.7-58.194-9.7-58.194l-12.47-80.364 1.386-79.67z"
        opacity="0.1"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#ffb9b9"
        d="M700.676 491.454s-22.862 86.599-22.17 128.859 9.7 101.147 9.7 101.147 1.386 25.634-.693 28.405 10.392 14.549 10.392 14.549l13.163-7.621 4.85-5.543v-4.849s-7.621-23.555-1.386-42.26 9.699-65.815 3.464-80.364l42.953-127.474z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#2f2e41"
        d="M699.29 753.329s-8.313-10.392-13.855-6.928-14.549 27.019-14.549 27.019-31.175 29.79-9.006 31.175 31.868-6.235 34.64-11.777 28.404-20.091 28.404-20.091-2.771-26.326-6.928-27.019-10.392 10.392-10.392 10.392z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#ffb9b9"
        d="M776.19 496.996v85.906a265.616 265.616 0 002.079 31.176c2.078 15.241-11.778 128.859-11.778 128.859l1.386 20.09 17.32-1.385 2.078-17.32 24.94-76.206s8.314-46.417 4.157-63.737 21.477-128.167 21.477-128.167z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#2f2e41"
        d="M762.335 758.178l5.13-1.115s3.876-8.584 8.725-6.505a52.3 52.3 0 019.643 5.778l2.135 3.92s4.85 10.393 11.085 17.32 13.163 22.17 2.77 23.555-24.247 2.079-28.404-1.385-1.385-9.006-5.542-10.392-7.62-5.542-6.928-6.928 1.386-24.248 1.386-24.248z"
        transform="translate(-94.554 -86.358)"
      />
      <circle
        cx="681.636"
        cy="109.274"
        r="30.79"
        fill="#ffb9b9"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M717.996 249.67l9.151-3.195s-3.609 5.966 15.79 9.43 39.487 4.69 48.494-6.661c0 0 3.465-1.653 8.314 3.197s7.621 3.464 7.621 3.464l-3.464 18.013-6.928 33.254-9.006 24.94-25.633-5.542-27.712-20.091-10.392-27.02v-24.94z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M802.516 259.369l6.928-4.85s23.555 4.157 24.94 15.242l-18.012 34.64a39.437 39.437 0 01-.692 29.79c-6.928 15.934-6.236 19.397-6.236 19.397l-4.85 20.784-87.984 6.928s-3.464-8.313-4.85-9.699-1.385-6.235 0-6.235 0-2.078-1.385-4.157-2.078-2.771 0-5.542-3.464-27.712-3.464-27.712v-27.019l-23.555-31.175s8.314-12.47 12.47-13.856 23.93-6.85 23.93-6.85l4.475 6.588 6.235 50.143 9.006 30.483 37.921-6.443 14.732-24.733 9.699-29.097z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M828.843 265.604l5.542 4.157s4.85 38.796 3.464 41.567-25.633 5.543-26.326 4.157 17.32-49.881 17.32-49.881z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#2f2e41"
        d="M714.532 246.206l6.928 45.724s-6.235 67.894-4.85 81.75l1.386 4.156s-2.771-1.385-3.464 1.386 0 11.777 0 11.777-4.157 4.157-5.542 27.019-18.013 77.593-11.778 79.671 43.646 12.47 63.737 10.392 92.141-26.326 91.448-32.561-35.332-94.22-35.332-94.22-6.235-10.392-6.235-11.777 8.313-6.928 6.235-12.47-13.163-63.737-13.163-63.737l7.62-41.568-10.391-2.771-7.62 40.182s-29.79 14.548-60.966 1.385l-6.928-47.11zM747.864 173.535s51.267 35.332 65.123 19.398-24.248-29.79-24.248-29.79l-31.868-3.464z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M668.21 549.4a27.027 27.027 0 10-8.48-10.524l-70.706 58.872-9.415-11.174-4.23 2.83-4.707-5.587 3.868-3.26-4.707-5.586-6.017 5.07 2.897 3.437-6.446 5.432-2.897-3.438-9.455 7.966 4.345 5.157 3.438-2.897 4.708 5.587-4.298 3.621 8.69 10.314-10.314 8.69 10.501 12.463zm17.653-39.492a17.882 17.882 0 11-19.339 16.294 17.882 17.882 0 0119.34-16.294z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill="#ffb9b9"
        d="M678.507 307.864s4.85 63.737 3.464 66.508 0 99.07 0 99.07-9.7 51.959 2.771 54.73 9.7-58.195 9.7-58.195l12.47-80.363-1.386-79.671z"
        transform="translate(-94.554 -86.358)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M688.899 267.682l-5.543 2.079s-10.391 43.646-7.62 43.646 36.025 4.156 36.025 2.77-6.235-35.332-6.235-35.332z"
        transform="translate(-94.554 -86.358)"
      />
    </svg>
  );
};
