import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
// eslint-disable-next-line
import { withLocation } from 'with-location';
import FooterContactInformation from './FooterContactInformation';
import FooterIconFlag from './FooterIconFlag';
import FooterDisclaimer from './FooterDisclaimer';
import FooterNavigation from './FooterNavigation';
import FooterNewsletter from './FooterNewsletter';

const useStyle = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const Footer = () => {
  const cls = useStyle();

  return (
    <>
      {/* <FooterNewsletter /> */}
      <Box
        bgcolor="primary.main"
        color="primary.contrastText"
        component="footer"
        px={1.5}
      >
        <Container maxWidth="lg">
          <FooterIconFlag />
          <Box my={4}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FooterContactInformation />
              </Grid>
              <Grid item xs={12} md={6}>
                <FooterNavigation />
              </Grid>
            </Grid>
          </Box>
          <Divider className={cls.divider} />
          <Box mt={2}>
            <FooterDisclaimer />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
