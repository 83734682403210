import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import axios from 'axios';
import {
  Dispatcher,
  Store,
} from 'q3-admin/src/containers/state';
import Button from '@material-ui/core/Button';
import { useAuth } from 'q3-ui-permissions';
import { useTranslation } from 'q3-ui-locale';
import NumberedList, {
  NumberedListItem,
} from './NumberedList';
import { COLLECTIONS } from './constants';
import { toDate } from './helpers';
import { useLock } from './OrderLock';

const Refresh = ({ id }) => {
  const { data } = React.useContext(Store);
  const { get: downloadChanges } =
    React.useContext(Dispatcher);
  const { t } = useTranslation('labels');

  const onClickHandler = () =>
    axios
      .get(
        `${COLLECTIONS.ORDERS.ENDPOINT}/${get(
          data,
          'id',
        )}/refresh/${id}`,
      )
      .then(() => downloadChanges());

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClickHandler}
      disabled={useLock()}
    >
      {t('refresh')}
    </Button>
  );
};

Refresh.propTypes = {
  id: PropTypes.string.isRequired,
};

const ExpansionValid = ({ item, ...rest }) => {
  const { HideByField } = useAuth(
    COLLECTIONS.ORDERS.COLLECTION_NAME,
  );

  const currency = get(item, 'bucket.currencyDefault');
  const id = get(item, 'id');
  const rate = get(item, 'bucket.price.trace.exchangeRate');
  const stamp = get(item, 'bucket.ZZ_VALID');
  const requiresRefresh = false;

  return (
    <HideByField op="Update" path="price" subfield="items">
      <CollapsiblePanel
        title="valid"
        warning={requiresRefresh}
        description={
          requiresRefresh ? 'requiresRefresh' : undefined
        }
        {...rest}
      >
        <NumberedList>
          {rate && (
            <NumberedListItem text="rate" value={rate} />
          )}
          {currency && (
            <NumberedListItem
              text="baseCurrencyOfProduct"
              value={currency}
            />
          )}
          <NumberedListItem
            text="stamp"
            value={toDate(stamp)}
          />
        </NumberedList>
        <Refresh id={id} />
      </CollapsiblePanel>
    </HideByField>
  );
};

ExpansionValid.propTypes = {
  id: PropTypes.string.isRequired,
  stamp: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  requiresRefresh: PropTypes.bool.isRequired,
};

export default ExpansionValid;
