import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@material-ui/core';
import { merge } from 'lodash';

// eslint-disable-next-line
const BrandTypography = ({ children }) => (
  <ThemeProvider
    theme={(theme) => {
      if (theme?.palette?.mode === 'dark') return theme;

      return merge({}, theme, {
        palette: {
          background: {
            default: '#F2F2F2',
          },
        },
      });
    }}
  >
    <Helmet>
      <link
        rel="preconnect"
        href="https://fonts.googleapis.com"
      />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap"
        rel="stylesheet"
      />
      <style>
        {
          ':root { --color-default: #323232 !important } body { background-color: #f4f4f4 }'
        }
      </style>
    </Helmet>
    {children}
  </ThemeProvider>
);

export default BrandTypography;
