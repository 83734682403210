import React from 'react';
import { browser } from '@lolly-labs/q3-ui-helpers';
// eslint-disable-next-line
import Cookies from 'js-cookie';

const { proxyLocalStorageApi } = browser;

const orderKey = 'GENTEK_ORDER_ID';
const paymentKey = 'x-display-gateway';
const sessionKey = 'GENTEK_CHECKOUT_SESSION_ID';
const currencyKey = 'x-display-currency';
const orderCurrencyKey = 'GENTEK_ORDER_CURRENCY_KEY';

export const isBrowserReady = () =>
  typeof window !== 'undefined';

export const OrderContext = React.createContext();

export const isDefined = (v) =>
  v !== undefined && v !== null && v !== 'null';

export const setCheckoutId = (id) =>
  proxyLocalStorageApi('setItem', sessionKey, id);

export const getCheckoutId = () =>
  proxyLocalStorageApi('getItem', sessionKey);

export const removeCheckoutId = () =>
  proxyLocalStorageApi('removeItem', sessionKey);

export const getOrderId = () =>
  proxyLocalStorageApi('getItem', orderKey);

export const setOrderId = (id) =>
  proxyLocalStorageApi('setItem', orderKey, id);

export const removeOrderId = () =>
  proxyLocalStorageApi('removeItem', orderKey);

export const removeCurrency = () =>
  Cookies.remove(currencyKey);

export const getCurrency = () => Cookies.get(currencyKey);

export const setCurrency = (v) =>
  Cookies.set(currencyKey, v);

export const getPaymentOption = () =>
  Cookies.get(paymentKey);

export const setPaymentOption = (paymentOption) =>
  Cookies.set(paymentKey, paymentOption);

export const getOrderCurrency = () =>
  proxyLocalStorageApi('getItem', orderCurrencyKey);

export const removeOrderCurrency = () =>
  proxyLocalStorageApi('removeItem', orderCurrencyKey);

export const setOrderCurrency = (currency) =>
  proxyLocalStorageApi(
    'setItem',
    orderCurrencyKey,
    currency,
  );
