import React from 'react';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';

const OrderItemCompanion = ({ item, ...rest }) => {
  return size(item.isCompanionOf) ? (
    <CollapsiblePanel
      title="Companion"
      description={`This item has been added to the order as a companion of ${item.isCompanionOf.join(
        ', ',
      )}. As such, editing capabilities may be limited.`}
      {...rest}
    />
  ) : null;
};

OrderItemCompanion.propTypes = {
  item: PropTypes.shape({
    isCompanionOf: [],
  }).isRequired,
};

export default OrderItemCompanion;
