import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@material-ui/core';
import { AuthContext } from 'q3-ui-permissions';
import { useTranslation } from 'q3-ui-locale';
import RichText from 'gatsby-theme-q3/src/components/RichText';
import axios from 'axios';

export const useAuthorizationError = () => {
  const [showDialog, setShowDialog] = React.useState(false);

  React.useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.code === 'mustAcceptToc'
        ) {
          setShowDialog(true);
        } else {
          setShowDialog(false);
        }

        return Promise.reject(error);
      },
    );
  }, []);

  return showDialog;
};

export const useScroll = () => {
  const [seen, setSeen] = React.useState(false);
  const ref = React.createRef();

  function hasReachedEnd() {
    const element = this;

    if (
      !seen &&
      Math.abs(
        element.scrollHeight -
          element.scrollTop -
          element.clientHeight,
      ) < 50
    )
      setSeen(true);
  }

  React.useEffect(() => {
    if (!ref.current) return undefined;
    const EVENT_TYPE = 'scroll';

    const detach = () =>
      ref.current.removeEventListener(
        EVENT_TYPE,
        hasReachedEnd,
      );

    if (!seen) {
      ref.current.addEventListener(
        EVENT_TYPE,
        hasReachedEnd,
      );
    } else {
      detach();
    }

    return detach;
  }, []);

  return {
    ref,
    seen,
  };
};

export const AcceptTermsAndConditionsButton = ({
  disabled,
}) => {
  const { update } = React.useContext(AuthContext);
  const { t } = useTranslation('labels');
  const cursor = disabled ? 'not-allowed' : undefined;

  const handleOnClick = () =>
    update({ acceptedTermsAndConditions: true }, () =>
      window.location.replace('/account'),
    );

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={disabled}
      onClick={handleOnClick}
      style={{
        cursor,
      }}
    >
      {t('accept')}
    </Button>
  );
};

AcceptTermsAndConditionsButton.propTypes = {
  disabled: PropTypes.bool,
};

AcceptTermsAndConditionsButton.defaultProps = {
  disabled: true,
};

const ContentTermsAndConditions = () => {
  const { privacy, terms } = useStaticQuery(graphql`
    {
      terms: contentfulPage(
        contentful_id: { eq: "3EB2IOokV18ScHDvsd1dv3" }
      ) {
        seoTitle
        body {
          raw
        }
      }

      privacy: contentfulPage(
        contentful_id: { eq: "69lxaURpnyO4ZmEhtMxPdv" }
      ) {
        seoTitle
        body {
          raw
        }
      }
    }
  `);

  return (
    <>
      <Box pb={2}>
        <Typography variant="h3">
          {terms?.seoTitle}
        </Typography>
        <Divider />
      </Box>
      <RichText json={JSON.parse(terms?.body?.raw)} />
      <Box pb={2}>
        <Typography variant="h3">
          {privacy?.seoTitle}
        </Typography>
        <Divider />
      </Box>
      <RichText json={JSON.parse(privacy?.body?.raw)} />
    </>
  );
};

export const DialogWrapper = () => {
  const { t } = useTranslation('titles');
  const { ref, seen } = useScroll();

  return (
    <>
      <DialogTitle
        style={{
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        {t('termsAndConditions')}
      </DialogTitle>
      <DialogContent ref={ref} dividers>
        <ContentTermsAndConditions />
      </DialogContent>
      <DialogActions>
        <AcceptTermsAndConditionsButton disabled={!seen} />
      </DialogActions>
    </>
  );
};

const TermsAndConditions = ({ children }) => {
  const showDialog = useAuthorizationError();

  React.useEffect(() => {
    if (typeof window === 'undefined') return;

    const el = document.getElementById('___gatsby');
    if (showDialog) {
      el.style.filter = 'blur(10px)';
    } else {
      el.style.filter = undefined;
    }
  }, [showDialog]);

  return (
    <>
      {children}
      {showDialog && (
        <Dialog
          open
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              maxHeight: 550,
            },
          }}
        >
          <DialogWrapper />
        </Dialog>
      )}
    </>
  );
};

TermsAndConditions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TermsAndConditions;
