/* eslint-disable import/prefer-default-export, react/jsx-filename-extension, react/prop-types */
import React from 'react';
import Cart from './src/containers/cart';
import Header from './src/components/header';
import Footer from './src/components/footer';
import BrandTypography from './src/components/BrandTypography';
import Currency from './src/components/Currency';
import TermsAndConditions from './src/components/TermsAndConditions';
import Recaptcha from './src/components/Recaptcha';
import GoogleTag from './src/components/GoogleTag';

export const wrapPageElement = ({
  element,
  props: { path },
}) => {
  const adminPath = 'admin';

  const inPath = (partial) =>
    typeof path === 'string' && path.includes(partial);

  return (
    <BrandTypography>
      <TermsAndConditions>
        <GoogleTag />

        <Recaptcha />
        {inPath(adminPath) ? (
          element
        ) : (
          <Currency>
            <Cart>
              <Header />
              {element}
              <Footer />
            </Cart>
          </Currency>
        )}
      </TermsAndConditions>
    </BrandTypography>
  );
};
