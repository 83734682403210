import AnnouncementIcon from '@material-ui/icons/Announcement';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CategoryIcon from '@material-ui/icons/Category';
import CommentIcon from '@material-ui/icons/Comment';

export const ADMINISTRATOR = 'q3-api-administrators';
export const RESELLER = 'q3-api-resellers';

export const COLLECTIONS = {
  CATEGORIES: {
    ENDPOINT: '/q3-api-categories',
    COLLECTION_NAME: 'q3-api-categories',
    RESOURCE_NAME: 'categories',
    RESOURCE_NAME_SINGULAR: 'category',
    ICON: CategoryIcon,
  },
  ORDERS: {
    COLLECTION_NAME: 'orders',
    RESOURCE_NAME: 'orders',
    RESOURCE_NAME_SINGULAR: 'order',
    ENDPOINT: '/orders',
  },
  PRODUCTS: {
    COLLECTION_NAME: 'products',
    RESOURCE_NAME: 'products',
    RESOURCE_NAME_SINGULAR: 'product',
    ENDPOINT: '/products',
  },
  LOGS: {
    COLLECTION_NAME: 'loggers',
    RESOURCE_NAME: 'logs',
    RESOURCE_NAME_SINGULAR: 'logs',
    ENDPOINT: '/loggers',
  },
  NOTICES: {
    COLLECTION_NAME: 'notices',
    RESOURCE_NAME: 'notices',
    RESOURCE_NAME_SINGULAR: 'notice',
    ENDPOINT: '/notices',
    ICON: AnnouncementIcon,
  },
  OUTREACH: {
    COLLECTION_NAME: 'outreaches',
    RESOURCE_NAME: 'outreaches',
    RESOURCE_NAME_SINGULAR: 'outreach',
    ENDPOINT: '/outreaches',
    ICON: CommentIcon,
  },
  RETURNS: {
    COLLECTION_NAME: 'returns',
    RESOURCE_NAME: 'returns',
    RESOURCE_NAME_SINGULAR: 'return',
    ENDPOINT: '/returns',
  },
  VENDORS: {
    ENDPOINT: '/q3-api-vendors',
    COLLECTION_NAME: 'q3-api-vendors',
    RESOURCE_NAME: 'vendors',
    RESOURCE_NAME_SINGULAR: 'vendor',
    ICON: BusinessCenterIcon,
  },
};

//= ===============================================================================
// Orders
//= ===============================================================================

export const QUOTE = 'Quote';
export const UNDER_REVIEW = 'Under Review';
export const BACKORDERED = 'Backordered';
export const DECLINED = 'Declined';
export const OPEN = 'Open';
export const CONFIRMED = 'Confirmed';
export const READY_FOR_DELIVERY = 'Ready for Pickup';
export const PREPAID = 'Prepaid';
export const PAID = 'Paid';
export const PARTIALLY_COMPLETED = 'Partially Completed';

export const PARTIALLY_COMPLETED_WITHOUT_BALENCE =
  'Partially Completed without Balance';

export const COMPLETED = 'Completed';
export const CANCELLED = 'Cancelled';
export const REFUNDED = 'Refunded';

export const ORDER_STATUS_ENUMS = [
  QUOTE,
  UNDER_REVIEW,
  BACKORDERED,
  DECLINED,
  OPEN,
  CONFIRMED,
  READY_FOR_DELIVERY,
  PREPAID,
  PAID,
  PARTIALLY_COMPLETED,
  PARTIALLY_COMPLETED_WITHOUT_BALENCE,
  COMPLETED,
  CANCELLED,
  REFUNDED,
];
