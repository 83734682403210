import React from 'react';
import { browser } from '@lolly-labs/q3-ui-helpers';

const HydrationFix = ({ children }) => {
  const [load, setLoad] = React.useState();

  React.useEffect(() => {
    if (browser.isBrowserReady()) setLoad(true);
  }, []);

  return load ? children : null;
};

export default HydrationFix;
