import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import get from 'lodash.get';

export default (props) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1149"
      height="784.079"
      data-name="Layer 1"
      viewBox="0 0 1149 784.079"
      {...props}
    >
      <path
        fill="#3f3d56"
        d="M460.5 805.04c0 20.43-95.81 37-214 37s-214-16.57-214-37c0-5.11 5.98-9.97 16.8-14.39 31.5-12.89 104.03-22.05 189.28-22.58q3.945-.03 7.92-.03c118.19 0 214 16.57 214 37z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        d="M238.58 768.07A121.496 121.496 0 0149.3 790.65c31.5-12.89 104.03-22.05 189.28-22.58z"
        opacity="0.1"
        transform="translate(-25.5 -57.96)"
      />
      <ellipse
        cx="112"
        cy="628.079"
        fill="#3f3d56"
        rx="112"
        ry="120"
      />
      <path
        fill="#3f3d56"
        d="M274.927 141.297c15.88 61.177 67.109 100.718 114.538 88.407"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M389.465 229.704c-16.058-61.864 13.776-123.041 66.704-136.78M307.456 138.773c15.085 58.111 51.764 98.782 82.009 90.93"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#3f3d56"
        d="M389.465 229.704c-20.52-79.049 12.38-155.864 73.557-171.744"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#a8a8a8"
        d="M371.616 235.17s12.071-3.534 15.06-7.097 17.233-10.589 19.23-6.637 23.694 16.084 9.984 19.761-32.478 6.146-36.717 4.867-7.557-10.894-7.557-10.894z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        d="M415.757 239.675c-13.71 3.677-32.478 6.147-36.717 4.867-3.229-.974-5.921-6.779-7.022-9.493l-.402.12s3.318 9.615 7.557 10.895 23.007-1.19 36.717-4.867c3.958-1.061 4.962-2.828 4.344-4.898-.223 1.405-1.531 2.586-4.477 3.376z"
        opacity="0.2"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#f2f2f2"
        d="M343 126.079H1149V593.079H343z"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M362 267.829H804V499.329H362z"
        opacity="0.3"
      />
      <path
        fill="#3f3d56"
        d="M838 257.737H1015.281V267.54400000000004H838z"
      />
      <path
        fill="#3f3d56"
        d="M838 275.842H1096V285.649H838z"
      />
      <path
        fill="#3f3d56"
        d="M838 319.597H1015.281V329.404H838z"
      />
      <path
        fill="#3f3d56"
        d="M838 392.018H1015.281V401.825H838z"
      />
      <path
        fill="#3f3d56"
        d="M838 337.702H1096V347.509H838z"
      />
      <path
        fill="#3f3d56"
        d="M838 355.807H1096V365.61400000000003H838z"
      />
      <path
        fill="#3f3d56"
        d="M838 373.913H1096V383.72H838z"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M838 435.772H930.789V461.421H838z"
      />
      <path
        fill="#a0616a"
        d="M339.988 465.874s16.656-47.195 28.686-32.388-18.507 39.791-18.507 39.791z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#575a89"
        d="M261.33 419.605l10.02 3.713s4.786 21.272 2.935 24.974-2.776.925 0 8.328 11.105 26.836 12.03 29.612 53.673-17.582 53.673-27.761c0 0 11.104 12.955 16.656 15.731 0 0-61.075 44.419-69.403 49.971s-12.956 2.776-20.359-3.701-38.866-60.15-38.866-60.15z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#2f2e41"
        d="M288.166 748.116s-.925 10.179-2.776 11.104-15.732 24.06 0 28.687 24.985 2.776 28.687 4.627 68.478 5.552 69.404-4.627-31.463-12.03-31.463-12.03-29.613-19.433-34.24-29.612-29.612 1.85-29.612 1.85zM100.313 766.623s-22.209 37.941-16.656 42.568 59.625 21.488 67.552 22.21c10.18.924 17.583-7.404 9.254-12.03s-25.91-24.06-25.91-30.539v-16.656z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#2f2e41"
        d="M79.03 518.62s-19.433 24.06 11.104 62.927l50.896 64.777s-31.463 49.97-32.388 75.88-7.403 20.36-9.254 31.464-10.18 5.552-3.701 13.88 30.537 15.732 37.94 9.255 7.403-14.807 7.403-18.508 12.956-24.985 13.881-31.463 35.165-59.225 35.165-59.225 11.104-31.463-1.851-49.045-21.517-42.519-21.517-42.519 38.174 46.22 65.01 57.325c0 0 10.179 1.85 8.328 6.478s0 7.403 2.776 12.03 22.21 58.299 28.687 72.18 3.702 22.209 11.105 26.836 40.717.925 40.717-3.702-4.627-16.657-7.403-23.134-24.986-81.434-23.135-94.39-.925-30.537-14.806-37.94-84.21-67.553-87.911-67.553-111.046-5.552-111.046-5.552z"
        transform="translate(-25.5 -57.96)"
      />
      <circle
        cx="239.532"
        cy="276.509"
        r="38.866"
        fill="#a0616a"
      />
      <path
        fill="#a0616a"
        d="M233.569 338.171s-12.03 21.284-26.837 24.06 38.867 45.344 38.867 45.344 12.955-40.717 24.06-42.568-36.09-26.836-36.09-26.836z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#575a89"
        d="M271.51 425.157c0 12.03-36.09 46.27-36.09 46.27l-34.24 28.686s-.925 10.18-4.627 11.105-8.328.925-4.627 6.477 12.956 17.583 4.627 19.433a68.028 68.028 0 01-7.579.722c-2.22.13-4.83.25-7.764.361-6.847.24-15.416.398-24.698.333q-3.956-.014-8.05-.092c-34.99-.694-75.521-4.72-72.209-17.98 5.553-22.21 1.851-29.613 13.881-41.643s31.463-53.672 31.463-53.672 38.866-65.702 71.255-65.702l6.727-1.768a15.406 15.406 0 0116.814 6.46c6.497 9.892 16.74 24.754 22.728 29.547 9.254 7.403 10.697 2.091 10.697 2.091s21.691 17.342 21.691 29.372z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#a0616a"
        d="M189.15 565.815s26.836 41.642 10.18 49.046-25.911-43.493-25.911-43.493z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#2f2e41"
        d="M239.623 330.08c1.492-.189 1.964-2.07 2.215-3.553 1.344-7.938 7.26-15.466 15.208-16.747a21.535 21.535 0 019.062.73 34.382 34.382 0 0111.627 5.723c1.836 1.388 3.602 3 5.81 3.649 1.459.428 7.651 3.18 9.098 3.647 3.181 1.024 5.814 4.353 9.09 3.694 3.134-.63 4.324-4.416 4.828-7.573 1.148-7.193-2.97-17.925-7.062-23.95-3.107-4.572-8.363-7.203-13.619-8.915a97.847 97.847 0 00-18.887-3.753c-8.645-1.02-17.6-1.562-25.884 1.11s-15.828 9.209-17.375 17.775c-.322 1.785-.383 3.613-.777 5.382-.964 4.318-3.82 7.947-5.487 12.045a23.193 23.193 0 00.708 18.802c2.307 4.64 6.32 9.1 5.454 14.209l7.141-5.63a5.186 5.186 0 002.384-6.071l-1.038-8.942c-.19-1.637-.356-3.404.463-4.834 3.174-5.544 4.554 3.515 7.041 3.202z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        d="M172.493 501.964l8.717 36.247c-8.79.315-20.423.481-32.749.24-3.11-9.327-6.08-19.053-8.356-28.159-8.329-33.313 5.552-84.21 12.955-108.27s25.911-30.537 25.911-30.537 9.254 2.776 21.284 11.104-27.762 119.375-27.762 119.375z"
        opacity="0.1"
        transform="translate(-25.5 -57.96)"
      />
      <path
        d="M179.896 500.113l9.078 37.737c-2.22.13-4.83.25-7.764.361-6.847.24-15.416.398-24.698.333-3.34-9.91-6.57-20.367-9.004-30.102-8.329-33.314 5.552-84.21 12.955-108.27s23.598 2.313 23.598 2.313-6.478-15.731 5.552-7.403-9.717 105.031-9.717 105.031z"
        opacity="0.1"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#575a89"
        d="M182.672 369.634s-18.507 6.478-25.91 30.538-21.284 74.956-12.956 108.27 25.911 74.956 25.911 74.956 17.582-12.956 24.06-10.18l-17.582-73.105s39.791-111.046 27.761-119.374-21.284-11.105-21.284-11.105z"
        transform="translate(-25.5 -57.96)"
      />
      <path
        fill="#3f3d56"
        d="M343 243.829H785V475.329H343z"
      />
      <ellipse
        cx="572.223"
        cy="364.111"
        fill="#fff"
        rx="50.017"
        ry="50.108"
      />
      <path
        fill={get(theme, 'palette.primary.main')}
        d="M597.723 363.423A59.777 59.777 0 10657.5 423.2a59.953 59.953 0 00-59.777-59.777zm-12.271 91.649l-30.636-30.636 8.593-8.593 22.071 22.072 46.586-46.586 8.593 8.593-55.207 55.15z"
        transform="translate(-25.5 -57.96)"
      />
    </svg>
  );
};
