import React from 'react';
import { Link } from 'gatsby';

// eslint-disable-next-line
const HeaderLogo = ({ children }) => (
  <Link
    to="/"
    aria-label="Return to homepage"
    style={{
      height: '100%',
      display: 'block',
      textDecoration: 'none',
    }}
  >
    {children}
  </Link>
);

export default HeaderLogo;
