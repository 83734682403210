import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Popover from '@material-ui/core/Popover';
import {
  getCurrency,
  getPaymentOption,
} from '../containers/utils';
import useResellerLogin from '../useResellerLogin';
// eslint-disable-next-line
import { useOpen } from 'useful-state';

const PaymentOption = () => {
  const currency = getCurrency();
  const paymentOption = getPaymentOption();
  const { isLoggedIn } = useResellerLogin();
  const { anchorEl, isOpen, close, open } = useOpen();

  return isLoggedIn && currency && paymentOption ? (
    <>
      <Button
        color="inherit"
        onClick={open}
        startIcon={<AccountBalanceWalletIcon />}
      >
        {paymentOption}
      </Button>
      <Popover
        anchorEl={anchorEl}
        onClose={close}
        open={isOpen}
      >
        <Box p={2} maxWidth={320}>
          <Typography component="small">
            You can change your payment preference during
            checkout. Please contact Gentek to change your
            default payment preference.
          </Typography>
        </Box>
      </Popover>
    </>
  ) : null;
};

export default PaymentOption;
