import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';

export const isCheckout = (pathname) =>
  typeof pathname === 'string' &&
  pathname.startsWith('/checkout');

const HideOnCheckout = ({ children }) => (
  <Location>
    {({ location: { pathname } }) =>
      !isCheckout(pathname) ? children : null
    }
  </Location>
);

HideOnCheckout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HideOnCheckout;
