import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { Context, Builders, helpers } from 'q3-ui-forms';
import { map, isFunction, sumBy, size } from 'lodash';
import { useAuth } from 'q3-ui-permissions';
import { useTranslation } from 'q3-ui-locale';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import { COLLECTIONS } from './constants';

const {
  ORDERS: { COLLECTION_NAME },
} = COLLECTIONS;

const OrderItemDateFix = ({ values }) => {
  const { setFieldValue } = React.useContext(
    Context.DispatcherState,
  );

  React.useEffect(() => {
    if (isFunction(setFieldValue))
      Object.entries(values).forEach(([key, value]) => {
        if (String(key).includes('date') && value === '')
          setFieldValue(key, null);
      });
  }, [JSON.stringify(values)]);

  return null;
};

OrderItemDateFix.defaultProps = {
  values: {},
};

OrderItemDateFix.propTypes = {
  values: PropTypes.shape({}),
};

const OrderItemFutureShipDates = ({
  item,
  update,
  ...props
}) => {
  const { HideByField } = useAuth(COLLECTION_NAME);
  const { t } = useTranslation();

  const renderQuantityAlert = () =>
    item.quantity >
      sumBy(item.futureShipDates, 'quantity') &&
    size(item.futureShipDates) > 0 ? (
      <Box mb={2} mt={-1}>
        <Alert severity="warning">
          {t(
            'descriptions:futureShipDatesLessThanQuantity',
          )}
        </Alert>
      </Box>
    ) : null;

  return (
    <HideByField op="Read" path="futureShipDate">
      <CollapsiblePanel title="futureShipDate" {...props}>
        {renderQuantityAlert()}
        <Builders.Form
          onSubmit={(values) => {
            if (values.futureShipDate)
              return update({
                ...values,
                futureShipDates: [],
                futureShipDate: helpers.castToUTC(
                  values.futureShipDate,
                ),
              });

            return update({
              ...values,
              futureShipDates: map(
                values?.futureShipDates,
                (f) => ({
                  ...f,
                  date: helpers.castToUTC(f.date),
                }),
              ),
            });
          }}
          collectionName={COLLECTION_NAME}
          initialValues={item}
          marshalSelectively
          keep={[
            'product',
            'quantity',
            'futureShipDate',
            'futureShipDates',
            'shipped',
            'backordered',
          ]}
        >
          {(values) => {
            return (
              <>
                <OrderItemDateFix values={values} />
                <Builders.Field
                  xl={6}
                  lg={6}
                  name="shipped"
                  type="number"
                  disabled
                />
                <Builders.Field
                  xl={6}
                  lg={6}
                  name="backordered"
                  type="number"
                  disabled
                />
                <Builders.Field
                  xl={12}
                  lg={12}
                  name="futureShipDate"
                  type="date"
                  label={t('labels:legacyFutureShipDate')}
                  helperText={t(
                    'helpers:legacyFutureShipDate',
                  )}
                />
                <Builders.Repeater group="futureShipDates">
                  <Builders.Field
                    xl={6}
                    lg={6}
                    name="date"
                    type="date"
                  />
                  <Builders.Field
                    xl={6}
                    lg={6}
                    name="quantity"
                    type="number"
                    helperText={t(
                      'helpers:futureShipDatesQuantity',
                    )}
                  />
                  <Builders.Field
                    name="comment"
                    type="text"
                    multiline
                    xl={12}
                    lg={12}
                    rows={3}
                  />
                </Builders.Repeater>
              </>
            );
          }}
        </Builders.Form>
      </CollapsiblePanel>
    </HideByField>
  );
};

OrderItemFutureShipDates.propTypes = {
  update: PropTypes.func.isRequired,
  item: PropTypes.shape({
    quantity: PropTypes.number,
    futureShipDates: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
      }),
    ),
  }).isRequired,
};

export default OrderItemFutureShipDates;
