import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'q3-ui-locale';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';

export const getAlerts = (item) =>
  get(item, 'bucket.ZZ_ALERTS', []);

const AlertRow = ({ item, ...rest }) => {
  const { t } = useTranslation('labels');
  return getAlerts(item).map((a) => (
    <CollapsiblePanel
      key={a.id}
      title="ZZAlert"
      description={t('alertOnMsg', { sku: a.sku })}
      warning
      {...rest}
    />
  ));
};

AlertRow.defaultProps = {
  item: [],
};

AlertRow.propTypes = {
  item: PropTypes.shape({
    bucket: PropTypes.shape({
      ZZ_REBATES: PropTypes.array,
    }),
  }),
};

export default AlertRow;
