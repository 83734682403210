import React from 'react';
import { useTheme } from '@material-ui/core/styles';

export default () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 829 744.16"
    >
      <defs>
        <linearGradient
          id="a"
          x1="382.9"
          x2="382.9"
          y1="692.59"
          y2="45.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            stopColor="gray"
            stopOpacity="0.25"
          />
          <stop
            offset="0.54"
            stopColor="gray"
            stopOpacity="0.12"
          />
          <stop
            offset="1"
            stopColor="gray"
            stopOpacity="0.1"
          />
        </linearGradient>
        <linearGradient
          id="b"
          x1="577.21"
          x2="577.21"
          y1="539.76"
          y2="383.34"
          xlinkHref="#a"
        />
        <linearGradient
          id="c"
          x1="422.99"
          x2="422.99"
          y1="586.26"
          y2="284.95"
          xlinkHref="#a"
        />
        <linearGradient
          id="d"
          x1="809.48"
          x2="809.48"
          y1="657.06"
          y2="582.42"
          xlinkHref="#a"
        />
        <linearGradient
          id="e"
          x1="728.61"
          x2="728.61"
          y1="677.75"
          y2="553.95"
          gradientTransform="rotate(-62.55 694.585 659.015)"
          xlinkHref="#a"
        />
      </defs>
      <circle
        cx="382.9"
        cy="368.92"
        r="323.67"
        fill="url(#a)"
      />
      <circle
        cx="382.9"
        cy="368.92"
        r="315.73"
        fill={theme.palette.primary.light}
      />
      <circle
        cx="280.81"
        cy="186.43"
        r="39.22"
        opacity="0.05"
      />
      <circle
        cx="400.77"
        cy="606.43"
        r="39.22"
        opacity="0.05"
      />
      <circle
        cx="439.99"
        cy="324.03"
        r="80.19"
        opacity="0.05"
      />
      <path
        fill="url(#b)"
        d="M694.73 539.76c-39.72 0-82-1.9-125.22-5.72-96.07-8.48-185.62-25.41-252.14-47.66-67-22.43-102.8-47.77-100.72-71.35 1.05-11.94 11.57-22.6 31.25-31.68l1 2.25c-18.82 8.68-28.85 18.65-29.82 29.65s7.35 22.84 24.75 34.82c17.1 11.77 42.09 23.19 74.29 34 66.35 22.2 155.69 39.08 251.58 47.54s186.81 7.49 256-2.74c33.58-5 60.19-11.84 79.09-20.43 19.24-8.74 29.49-18.82 30.47-29.95 1.12-12.67-10.33-26.56-33.1-40.16l1.27-2.13c24 14.33 35.53 28.63 34.3 42.51-2.08 23.59-41.74 42.27-111.66 52.6-38.07 5.61-82.84 8.44-131.34 8.45z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="none"
        stroke="#bdbdbd"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M717.83 357.89c22.71 13.57 34.91 27.67 33.7 41.33-4.19 47.47-168.46 71.75-366.91 54.23S28.69 383.25 32.88 335.78c1-11.73 11.85-22 30.53-30.66"
        opacity="0.5"
      />
      <path
        fill="url(#c)"
        d="M608.39 586.26c-28.62-12.34-57.83-26.11-86.81-40.94-85.87-43.92-162.52-93.21-215.83-138.81-53.72-45.94-77.36-82.85-66.58-103.94 5.46-10.68 19.21-16.61 40.85-17.64l.12 2.47c-20.7 1-33.74 6.46-38.77 16.29s-1.76 23.93 9.88 41.56c11.43 17.32 30.31 37.3 56.11 59.36 53.16 45.5 129.64 94.68 215.34 138.51 28.94 14.8 58.09 28.55 86.67 40.87z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="none"
        stroke="#bdbdbd"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M428 508.25c-28.09-12.11-57.19-25.79-86.74-40.9C163.89 376.64 37.7 268.7 59.4 226.28c5.36-10.48 19.25-16 39.81-17"
      />
      <path
        fill="url(#d)"
        d="M840.73 657.06c-26 0-62.67-8.5-112.17-25.56l.81-2.34c94.46 32.54 141.19 33.86 156.25 4.41 5.79-11.32.39-28.49-15.61-49.65l2-1.49c16.86 22.28 22.19 39.87 15.84 52.27-7.65 14.89-22.73 22.35-47.12 22.36z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="none"
        stroke="#bdbdbd"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M686 503.82c16 21.1 22 38.75 15.73 51-14.39 28.13-56.6 31-157.76-3.8"
      />
      <g fill="#47e6b1" opacity="0.5">
        <path d="M94.38 661.7H97.6V679.9300000000001H94.38z" />
        <path
          d="M279.38 738.56H282.6V756.79H279.38z"
          transform="rotate(90 226.915 616.745)"
        />
      </g>
      <g fill="#47e6b1" opacity="0.5">
        <path d="M748.57 3.22H751.7900000000001V21.45H748.57z" />
        <path
          d="M933.57 80.08H936.7900000000001V98.31H933.57z"
          transform="rotate(90 881.105 -41.735)"
        />
      </g>
      <g fill="#47e6b1" opacity="0.5">
        <path d="M818.28 500.83H821.5V519.06H818.28z" />
        <path
          d="M1003.28 577.7H1006.5V595.9300000000001H1003.28z"
          transform="rotate(90 950.82 455.88)"
        />
      </g>
      <g fill="#47e6b1" opacity="0.5">
        <path d="M105.1 47.19H108.32V65.42H105.1z" />
        <path
          d="M290.1 124.05H293.32000000000005V142.28H290.1z"
          transform="rotate(90 237.64 2.24)"
        />
      </g>
      <path
        fill="#4d8af0"
        d="M385.22 801.09a3.94 3.94 0 01-2.2-4.76 1.89 1.89 0 00.09-.44 2 2 0 00-3.55-1.31 1.89 1.89 0 00-.22.39 3.94 3.94 0 01-4.76 2.2 1.89 1.89 0 00-.44-.09 2 2 0 00-1.31 3.55 1.89 1.89 0 00.39.22 3.94 3.94 0 012.2 4.76 1.89 1.89 0 00-.09.44 2 2 0 003.55 1.31 1.89 1.89 0 00.22-.39 3.94 3.94 0 014.76-2.2 1.89 1.89 0 00.44.09 2 2 0 001.31-3.55 1.89 1.89 0 00-.39-.22zM581.47 814a3.94 3.94 0 01-2.2-4.76 1.89 1.89 0 00.09-.44 2 2 0 00-3.55-1.31 1.89 1.89 0 00-.22.39 3.94 3.94 0 01-4.76 2.2 1.89 1.89 0 00-.44-.09 2 2 0 00-1.31 3.55 1.89 1.89 0 00.39.22 3.94 3.94 0 012.2 4.76 1.89 1.89 0 00-.09.44 2 2 0 003.55 1.31 1.89 1.89 0 00.22-.39 3.94 3.94 0 014.76-2.2 1.89 1.89 0 00.44.09 2 2 0 001.31-3.55 1.89 1.89 0 00-.39-.22zM969.7 296a3.94 3.94 0 01-2.2-4.76 1.89 1.89 0 00.09-.44 2 2 0 00-3.55-1.31 1.89 1.89 0 00-.22.39 3.94 3.94 0 01-4.76 2.2 1.89 1.89 0 00-.44-.09 2 2 0 00-1.31 3.55 1.89 1.89 0 00.39.22 3.94 3.94 0 012.2 4.76 1.89 1.89 0 00-.09.44 2 2 0 003.55 1.31 1.89 1.89 0 00.22-.39 3.94 3.94 0 014.76-2.2 1.89 1.89 0 00.44.09 2 2 0 001.31-3.55 1.89 1.89 0 00-.39-.22zM205.26 579.8a3.94 3.94 0 01-2.2-4.76 1.89 1.89 0 00.09-.44 2 2 0 00-3.55-1.31 1.89 1.89 0 00-.22.39 3.94 3.94 0 01-4.76 2.2 1.89 1.89 0 00-.44-.09 2 2 0 00-1.31 3.55 1.89 1.89 0 00.39.22 3.94 3.94 0 012.2 4.76 1.89 1.89 0 00-.09.44 2 2 0 003.55 1.31 1.89 1.89 0 00.22-.39 3.94 3.94 0 014.76-2.2 1.89 1.89 0 00.44.09 2 2 0 001.31-3.55 1.89 1.89 0 00-.39-.22z"
        opacity="0.5"
        transform="translate(-185 -76.87)"
      />
      <circle
        cx="6.43"
        cy="238.08"
        r="6.43"
        fill="#f55f44"
        opacity="0.5"
      />
      <circle
        cx="530.86"
        cy="22.52"
        r="6.43"
        fill="#4d8af0"
        opacity="0.5"
      />
      <circle
        cx="176.95"
        cy="6.43"
        r="6.43"
        fill="#47e6b1"
        opacity="0.5"
      />
      <circle
        cx="653.12"
        cy="691.73"
        r="6.43"
        fill="#f55f44"
        opacity="0.5"
      />
      <path
        fill="url(#e)"
        d="M686.59 566.15a7.62 7.62 0 00-10.27 3.25l-.78 1.5-1.78-.93-15.91-8.26-3.46-1.8a3 3 0 00-1.57-.33c-15.23-5-27.45 4.63-35.33 19.8a49.7 49.7 0 00-5.49 16.68l-.18-.09c-.88 6.25-.06 12.11 3 17.07l.26-.51a24.56 24.56 0 005.82 6.56 2.32 2.32 0 001.19 1.52l21.78 11.31-1.66 3.19a7.62 7.62 0 003.25 10.27l5.17-10 26.86 13.95 1.76-3.38 1.76-3.38 2.63-5.07h.09l-6.1 11.74 12.85 6.76c15.64 8.12 27.59-2.31 36.27-18.47v-.09l.53-1v-.09l.45-.89c8.3-16.45 10-32.21-5.72-40.36l-12.81-6.6-7.07 13.62h-.09l3.61-6.95 1.66-3.19 1.85-3.57-26.86-14 4.29-8.26zm-41 58.08l.08.07h-.09z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#4f617d"
        d="M650.61 597.43H707.9300000000001V626.0899999999999H650.61z"
        transform="rotate(-62.55 523.576 725.627)"
      />
      <path
        fill="#ff656a"
        d="M724 634.88v-.09c-8.21 15.8-19.62 26.25-34.67 18.44l-12.11-6.29L707 589.71l12.1 6.29c15.05 7.82 13.11 23.08 4.9 38.88z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#4a5c81"
        d="M724 634.79zM714.65 648.3a55.65 55.65 0 009.35-13.46 59.11 59.11 0 005.17-13.29l-5.17-2.69-14 27z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#4a5c81"
        d="M675.15 617.71h37.62v1.05a4.32 4.32 0 01-4.32 4.32h-29a4.32 4.32 0 01-4.32-4.32v-1.05h.02z"
        transform="rotate(-62.55 538.263 734.266)"
      />
      <path
        fill="#ff6469"
        d="M653.93 599.31h1.4v60.9h-7.16v-55.13a5.76 5.76 0 015.76-5.77z"
        transform="rotate(-62.55 496.062 743.634)"
      />
      <path
        fill="#ff6469"
        d="M674.68 548.27h1.4a5.76 5.76 0 015.76 5.76v55.14h-7.16v-60.9z"
        transform="rotate(-62.55 522.565 692.581)"
      />
      <path
        fill="#ff656a"
        d="M673.65 572.28l-29.72 57.23-15-7.77c-16.53-8.59-16.44-24.81-8.24-40.61 8.21-15.8 21.43-25.2 38-16.62z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#fff6d5"
        d="M618.18 612.8l6-11.5-8.79-4.56c-.86 5.88-.08 11.39 2.79 16.06zM644.89 561.37l-6 11.5-8.79-4.56c4.37-4.07 9.32-6.61 14.79-6.94z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#ff6469"
        d="M646.69 633.22h5v7.16h-10.76V639a5.76 5.76 0 015.76-5.78z"
        transform="rotate(-62.55 490.603 750.673)"
      />
      <path
        fill="#ff6469"
        d="M674.69 568.22h10.75v7.16h-5a5.76 5.76 0 01-5.76-5.76v-1.4h.01z"
        transform="rotate(117.45 610.913 477.142)"
      />
      <path
        fill="#53678b"
        d="M677.44 580.31l-24.86 47.87-21.58-19.3a10.15 10.15 0 01-2.24-12.24l9.24-17.78a10.15 10.15 0 0111.35-5.2z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#658bc8"
        d="M653.67 626.5c-10.54-5.47-14.09-19.51-7.93-31.37s19.69-17 30.22-11.55"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#ff656a"
        d="M494.16 504.45L469.39 552.14 464.62 549.66 467.98 547.08 488.33 507.91 489.39 501.97 494.16 504.45z"
      />
      <path
        d="M494.16 504.45L469.39 552.14 464.62 549.66 467.98 547.08 488.33 507.91 489.39 501.97 494.16 504.45z"
        opacity="0.05"
      />
      <path
        fill="#53678b"
        d="M664.63 606.36H671.79V613.52H664.63z"
        transform="rotate(-62.55 512.52 723.813)"
      />
      <ellipse
        cx="671.4"
        cy="611.6"
        fill="#53678b"
        rx="7.16"
        ry="1.79"
        transform="rotate(-62.55 515.698 725.462)"
      />
      <path
        d="M688.86 644.9a12.27 12.27 0 0017.47-3.9M711.16 602a12.27 12.27 0 016.84 16.52M633.89 602.21s-1.41-12.84 11.56-22.25M639.89 605.33s-1.41-12.84 11.56-22.25"
        opacity="0.05"
        transform="translate(-185 -76.87)"
      />
      <circle
        cx="652.2"
        cy="609.7"
        r="5.37"
        fill="#4a77be"
        transform="rotate(-62.55 496.497 723.569)"
      />
      <circle
        cx="659.69"
        cy="591.39"
        r="3.58"
        fill="#4a77be"
        transform="rotate(-62.55 503.991 705.257)"
      />
      <path
        fill="#cdefff"
        d="M679.67 595.44h1.05a2.16 2.16 0 012.16 2.16v11.75a2.21 2.21 0 01-2.21 2.21h-3.17v-14a2.16 2.16 0 012.17-2.12z"
        transform="rotate(-101.5 555.45 640.623)"
      />
      <path
        d="M680.15 597.59h5.37V604a4.32 4.32 0 01-4.32 4.32h-1.05v-10.73z"
        opacity="0.05"
        transform="rotate(-101.45 558.896 640.174)"
      />
      <path
        fill="#cdefff"
        d="M672 623.88h9.39V635H672a4.45 4.45 0 01-4.45-4.45v-2.17a4.45 4.45 0 014.45-4.5z"
        transform="rotate(-62.55 518.755 743.286)"
      />
      <path
        fill="#cdefff"
        d="M678.53 633.27l-1.79 3.83c-1.94 3.22-6.64 4.1-10.69 2l-4.7-2.44 2.55-4.92z"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#cdefff"
        d="M657.16 626.1h1.08a2.23 2.23 0 012.23 2.23v8.85h-5.54v-8.85a2.23 2.23 0 012.23-2.23z"
        transform="rotate(-62.55 502.002 745.506)"
      />
      <path
        d="M676.92 626a8.27 8.27 0 005.3.8l.26-.51-9.6-5a8.28 8.28 0 004.04 4.71z"
        opacity="0.05"
        transform="translate(-185 -76.87)"
      />
      <path
        fill="#cdefff"
        d="M683.62 601.49H693v11.08h-9.39a4.45 4.45 0 01-4.45-4.45v-2.17a4.45 4.45 0 014.45-4.45z"
        transform="rotate(117.45 616.939 512.363)"
      />
      <path
        d="M684.72 611a8.27 8.27 0 013.71 3.88l-.26.51-9.6-5a8.28 8.28 0 016.15.61z"
        opacity="0.05"
        transform="translate(-185 -76.87)"
      />
      <circle
        cx="680.82"
        cy="618.16"
        r="8.31"
        fill="#cdefff"
        transform="rotate(-62.55 525.127 732.034)"
      />
      <circle
        cx="680.41"
        cy="618.29"
        r="8.31"
        fill="#cdefff"
        transform="rotate(-62.55 524.71 732.164)"
      />
      <path
        fill="#658bc8"
        d="M673 614.46a8.3 8.3 0 00.37 8.3A8.31 8.31 0 00680 610a8.3 8.3 0 00-7 4.46z"
        transform="translate(-185 -76.87)"
      />
    </svg>
  );
};
