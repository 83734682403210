import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'q3-ui-locale';
import CollapsiblePanel from 'q3-ui/src/collapsiblePanel';
import useOrderAuth from './useOrderAuth';

const Restricted = ({ item, ...rest }) => {
  const { HideByField } = useOrderAuth();
  const { t } = useTranslation('descriptions');

  const message = get(
    item,
    'bucket.restricted.comment',
    t('restrictedItem'),
  );

  const enforce = get(
    item,
    'bucket.restricted.enforce',
    false,
  );

  return enforce ? (
    <HideByField
      op="Read"
      subfield="bucket"
      path="restricted"
    >
      <CollapsiblePanel
        title="Restriction"
        description={<>{message}</>}
        error
        {...rest}
      />
    </HideByField>
  ) : null;
};

Restricted.propTypes = {
  item: PropTypes.shape({
    bucket: PropTypes.shape({
      restricted: PropTypes.object,
    }),
  }).isRequired,
};

export default Restricted;
