import React from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { get, find } from 'lodash';
import HeaderLogoDesktop from './HeaderLogoDesktop';

const useStyle = makeStyles((theme) => ({
  address: {
    fontStyle: 'normal',
    marginTop: theme.spacing(1),
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    margin: `${theme.spacing(0.5)} 0`,
  },
  link: {
    textDecoration: 'none !important',
  },
}));

const FooterContactInformation = () => {
  const cls = useStyle();
  const { contentfulConfig } = useStaticQuery(graphql`
    {
      contentfulConfig {
        streetLine
        city
        region
        postalCode
        socialMedia
        departments {
          name
          fax
          email
          local
          tollFree
        }
      }
    }
  `);

  const renderDepartment = (label, key, type = 'tel') => {
    const department = find(
      contentfulConfig?.departments,
      ({ name }) => name === 'Sales',
    );

    const v = get(department, key);
    return v ? (
      <Box component="li" className={cls.listItem}>
        <Box
          component="strong"
          display="inline-block"
          mr={1}
        >
          {label}
        </Box>
        <Link
          className={cls.link}
          color="inherit"
          href={`${type}:${v}`}
        >
          {v}
        </Link>
      </Box>
    ) : null;
  };

  return (
    <>
      <span style={{ filter: 'grayscale(1) invert(1)' }}>
        <HeaderLogoDesktop />
      </span>
      <Box className={cls.address} component="address">
        {[
          contentfulConfig.streetLine,
          contentfulConfig.city,
          contentfulConfig.region,
          contentfulConfig.postalCode,
        ].join(', ')}
      </Box>
      <Box className={cls.list} component="ul" mt={2}>
        {renderDepartment('Email', 'email', 'mailTo')}
        {renderDepartment('Toll-Free', 'tollFree')}
        {renderDepartment('Phone', 'local')}
        {renderDepartment('Fax', 'fax')}
      </Box>
    </>
  );
};

export default FooterContactInformation;
