import React from 'react';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { object } from '@lolly-labs/q3-ui-helpers';
import NumberedList, {
  NumberedListItem,
} from './NumberedList';

const isNs = (item) =>
  [
    get(item, 'bucket.itemType'),
    get(item, 'bucket.type'),
  ].includes('NS');

export const withPhysicalInventory = (Component) => (
  props,
) => {
  // eslint-disable-next-line
  const { product } = props;

  if (isNs(props))
    return (
      <Typography>
        Services are always available for order.
      </Typography>
    );

  if (!product)
    return (
      <Typography>
        Cannot fetch inventory without a product reference.
      </Typography>
    );

  return <Component {...props} />;
};

export const ProductInventory = ({
  alias,
  product,
  select,
}) => {
  const [inventory, setInventory] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [err, setErr] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(
        alias
          ? `/products-alias?sku=${product}`
          : `/products/${product}`,
      )
      .then((r) => {
        setInventory({
          ...get(r, 'data.product.inventory'),
          units: get(r, 'data.product.units', 0),
        });
        setLoading(false);
      })
      .catch(() => {
        setErr(true);
        setLoading(false);
      });
  }, []);

  if (loading) return 'Thinking...';

  if (err || !object.hasKeys(inventory))
    return 'Could not fetch inventory';

  if (select) return get(inventory, select, 0);

  return (
    <NumberedList>
      {Object.entries(
        pick(inventory, [
          'hasAvailable',
          'onPoTotal',
          'onCustomerOrder',
          'onHand',
        ]),
      ).map(([key, value]) =>
        typeof value === 'string' ||
        typeof value === 'number' ? (
          <NumberedListItem
            key={key}
            text={key}
            value={value}
          />
        ) : null,
      )}
    </NumberedList>
  );
};

ProductInventory.defaultProps = {
  alias: false,
  select: undefined,
};

ProductInventory.propTypes = {
  alias: PropTypes.bool,
  product: PropTypes.string.isRequired,
  select: PropTypes.string,
};

export default withPhysicalInventory(ProductInventory);
