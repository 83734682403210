import React from 'react';
import { Launcher, Drawer } from 'q3-ui-cart';
import { AuthContext, useAuth } from 'q3-ui-permissions';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import CartLineItem from 'gatsby-theme-gentek/src/components/CartItemAccordionBuilder';

const HeaderShoppingCart = () => {
  const state = React.useContext(AuthContext);
  const { Hide } = useAuth(
    COLLECTIONS.ORDERS.COLLECTION_NAME,
  );

  return state?.state?.profile?.ordersEnabled ? (
    <Hide op="Create">
      <span id="cart-toggle">
        <Launcher>
          {(close, isOpen) => (
            <Drawer
              isOpen={isOpen}
              close={close}
              shopPath="/products"
              checkoutPath="/checkout"
            >
              <CartLineItem />
            </Drawer>
          )}
        </Launcher>
      </span>
    </Hide>
  ) : null;
};

export default HeaderShoppingCart;
