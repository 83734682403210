import React from 'react';
import { navigate } from '@reach/router';
import { useTranslation } from 'q3-ui-locale';
import { get } from 'lodash';
import { AccountMenu } from 'q3-ui/src/toolbar';
import { destroySession } from 'q3-ui-permissions';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CurrencySelect from './CurrencySelect';
import useResellerLogin from '../useResellerLogin';
import HeaderShoppingCart from './HeaderShoppingCart';
import PaymentOption from './PaymentOption';
import HideOnCheckout from './HideOnCheckout';

const toAccount = () => navigate('/account');
const toOrders = () => navigate('/account/orders');
const toReturns = () => navigate('/account/returns');

const asLinkOption = (label, onClick) => ({
  label,
  onClick,
});

const useStyle = makeStyles(() => ({
  root: {
    display: 'inline-block',
    lineHeight: 1,
    textAlign: 'right',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    maxWidth: 140,
    whiteSpace: 'nowrap',
  },
}));

const HeaderAccountMenu = () => {
  const {
    isLoggedIn,
    firstName,
    lastName,
    customer,
    photo,
  } = useResellerLogin();
  const { t } = useTranslation('labels');
  const { root } = useStyle();

  const customerName = get(customer, 'name');
  const userName = `${firstName} ${lastName}`;

  const accountMenuOptions = [
    asLinkOption(t('account'), toAccount),
    asLinkOption(t('orders'), toOrders),
    asLinkOption(t('returns'), toReturns),
    asLinkOption(t('logout'), () => destroySession()),
  ];

  return isLoggedIn ? (
    <HideOnCheckout>
      <Fade in>
        <Box
          role="toolbar"
          bgcolor="background.default"
          color="primary.main"
          px={2}
          py={1}
        >
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <PaymentOption />
            </Grid>
            <Grid item>
              <CurrencySelect />
            </Grid>
            <Grid item>
              <HeaderShoppingCart />
            </Grid>
            <Grid item>
              <AccountMenu
                isLoggedIn
                items={accountMenuOptions}
                name={
                  <Hidden mdDown>
                    <span className={root}>
                      <strong>{customerName}</strong>
                      <br />
                      {userName}
                    </span>
                  </Hidden>
                }
                profileImgSrc={photo}
              />
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </HideOnCheckout>
  ) : null;
};

export default HeaderAccountMenu;
